/**
 * @generated SignedSource<<f5e05a8377a81c85ba225b8e38e8f3ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatsTableHomepageRowLiveData$data = {
  readonly statsV2: {
    readonly floorPrice: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    } | null;
    readonly oneDayChange: number;
    readonly oneDayVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
    readonly oneHourChange: number;
    readonly oneHourVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
    readonly sevenDayChange: number;
    readonly sevenDayVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
    readonly sixHourChange: number;
    readonly sixHourVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
  };
  readonly " $fragmentType": "StatsTableHomepageRowLiveData";
};
export type StatsTableHomepageRowLiveData$key = {
  readonly " $data"?: StatsTableHomepageRowLiveData$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatsTableHomepageRowLiveData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StatsTableHomepageRowLiveData"
};

(node as any).hash = "c5fe8d7d0b29184257d2d7a4c6e066c5";

export default node;
