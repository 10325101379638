import React from "react"
import { classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { Flex } from "@/design-system/Flex"
import { CategoryNavigation } from "@/features/categorization/CategoryNavigation"
import { CategoryTabs } from "@/features/categorization/CategoryNavigation/types"
import {
  VISIBLE_CATEGORIES,
  useCategoryNameGetter,
} from "@/features/categorization/collection-edit/categoryUtils"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  color?: string
  isPrimaryDropActive?: boolean
}

export const CategoryLinks = ({
  color,
  isPrimaryDropActive = false,
}: Props) => {
  const t = useTranslate("categorization")
  const { query } = useRouter()
  const categoryNameGetter = useCategoryNameGetter()

  const shownCategories = [
    ...[{ label: t("top.category.all", "All"), id: undefined, path: "" }],
    ...VISIBLE_CATEGORIES.map(category => {
      return {
        label: categoryNameGetter(category.slug),
        id: category.slug,
        path: `category/${category.slug}`,
      }
    }),
  ]

  const currentCategory = query.categorySlug
    ? (String(query.categorySlug) as CategoryTabs)
    : undefined

  return (
    <ContentContainer
      className={classNames(isPrimaryDropActive && "absolute z-[4]")}
      margin={
        !isPrimaryDropActive
          ? { _: "12px 0 24px 0", md: "24px 0 32px 0" }
          : undefined
      }
      padding={
        isPrimaryDropActive
          ? {
              _: "12px 16px 0 16px",
              md: "24px 0 0 16px",
              lg: "24px 0 0 16px",
              xl: "24px 0 0 32px",
              xxl: "24px 0 0 64px",
            }
          : undefined
      }
      width={isPrimaryDropActive ? "100%" : undefined}
    >
      <CategoryNavigation
        color={color}
        currentTab={currentCategory}
        tabs={shownCategories}
      />
    </ContentContainer>
  )
}

const ContentContainer = styled(Flex)`
  align-items: center;
  max-width: 100%;
`
