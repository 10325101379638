import React from "react"
import {
  Media,
  Text,
  Container,
  SpaceBetween,
  FlexColumn,
} from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCarouselSkeleton } from "@/features/home/components/HomePageCarouselSkeleton.react"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useTranslate } from "@/hooks/useTranslate"
import { LEARN_URL } from "../../../../constants"
import { ASSET_URL, useCarouselSlides } from "./constants"
import { HomePageSimpleCard } from "./HomePageSimpleCard.react"

type Props = {
  category?: string | undefined
}

export const HomePageLearnCenterCarousel = ({ category }: Props) => {
  const t = useTranslate("home")
  const isMounted = useIsMounted()
  const carouselSlides = useCarouselSlides(category)

  if (!isMounted) {
    return <HomePageCarouselSkeleton />
  }

  const learnMoreButtonText = t("learnCenter.learnMore", "Learn more")
  const learnCenterTitle = t("learnCenter.title", "NFT 101")

  const slides = carouselSlides.map((entry, index) => {
    const { title, path, fileName } = entry
    return {
      id: `${index}`,
      collection: (
        <HomePageSimpleCard
          eventParams={{
            index,
            sectionTitle: "NFT 101",
          }}
          eventSource="LearnCenterShelfClick"
          href={`${LEARN_URL}/${path}`}
          imageUrl={`${ASSET_URL}/${fileName}`}
          title={title}
        />
      ),
    }
  })

  // Overflow hidden used for Carousel "card" variants that require a visible overflow
  return (
    <Container className="overflow-x-hidden" key={learnCenterTitle}>
      <Block>
        <Grid.Item>
          <Media greaterThanOrEqual="lg">
            <SpaceBetween className="mb-4 items-center">
              <FlexColumn>
                <Text.Heading size="medium">{learnCenterTitle}</Text.Heading>
              </FlexColumn>
              <Link eventSource="HomePageLearnCenterCarousel" href={LEARN_URL}>
                <Button variant="secondary">{learnMoreButtonText}</Button>
              </Link>
            </SpaceBetween>
          </Media>
          <Media lessThan="lg">
            <SpaceBetween className="mb-3 items-center">
              <FlexColumn>
                <Text.Heading size="small">{learnCenterTitle}</Text.Heading>
              </FlexColumn>
              <Link eventSource="HomePageLearnCenterCarousel" href={LEARN_URL}>
                <Button size="small" variant="secondary">
                  {learnMoreButtonText}
                </Button>
              </Link>
            </SpaceBetween>
          </Media>

          <Block
            data-testid="Home--learn-center-carousel"
            marginX={{ _: "-16px", sm: "0px" }}
          >
            <Media lessThan="sm">
              <Carousel
                centeredSlides
                centeredSlidesBounds
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="learn_center_carousel_mobile"
                enableArrowControls={false}
                enableFreeScroll
                enableMousewheel
                id="learn-center-carousel-mobile"
                renderSlide={slide => slide.collection}
                responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
                showScrollbar={false}
                slides={slides}
                slidesOffsetAfter={16}
                slidesOffsetBefore={16}
                speed={500}
                variant="card"
              />
            </Media>
            <Media greaterThanOrEqual="sm">
              <Carousel
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="learn_center_carousel"
                enableArrowControls
                enableFreeScroll
                enableMousewheel
                id="learn-center-carousel"
                renderSlide={slide => <Block>{slide.collection}</Block>}
                responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
                showScrollbar={false}
                slides={slides}
                speed={500}
                variant="card"
              />
            </Media>
          </Block>
        </Grid.Item>
      </Block>
    </Container>
  )
}
