import { getHomepageRowData } from "@/features/stats/components/StatsTableHomepageRow"
import type { HomePageStatsTables_data$data } from "@/lib/graphql/__generated__/HomePageStatsTables_data.graphql"
import { StatsTimeWindow } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { HomePageStatsColumnData } from "./HomePageStats.react"

export const getSortedHomepageRows = (
  data: HomePageStatsTables_data$data["trendingCollections"],
  timeWindow: StatsTimeWindow | undefined,
  sortByVolume: boolean,
  indexStart: number,
) => {
  const rows = getNodes(data)

  const start = indexStart - 1
  const end = indexStart === 1 ? 5 : Math.min(rows.length, 10)

  const sort = (a: (typeof rows)[number], b: (typeof rows)[number]) => {
    // Index doesn't matter here, we are coercing it to a HomepageRow type
    const { volume: aVolume } = getHomepageRowData({
      data: { ...a, index: 0 },
      timeWindow,
    })
    const { volume: bVolume } = getHomepageRowData({
      data: { ...b, index: 0 },
      timeWindow,
    })
    if (bn(aVolume.eth).isGreaterThan(bVolume.eth)) {
      return -1
    }
    if (bn(bVolume.eth).isGreaterThan(aVolume.eth)) {
      return 1
    }

    return 0
  }

  const hasVolume = (row: (typeof rows)[number]): boolean => {
    const { volume } = getHomepageRowData({
      data: { ...row, index: 0 },
      timeWindow,
    })
    return bn(volume.unit).isGreaterThan(0)
  }

  const sortedRows = sortByVolume ? rows.sort((a, b) => sort(a, b)) : rows
  return sortedRows
    .filter(hasVolume)
    .slice(start, end)
    .map((collection, index) => ({
      ...collection,
      index,
    }))
}

export const getCollectionSlugs = (
  data: HomePageStatsColumnData | null,
): Array<string> => {
  if (!data) {
    return []
  }
  return data.edges
    .map(edge => {
      return edge?.node?.slug
    })
    .filter((slug): slug is string => !!slug)
    .sort()
}
