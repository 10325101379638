/**
 * @generated SignedSource<<fefc7db8820816f894b4143d288cb320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageStatsTables_data$data = {
  readonly topCollectionsByCategory: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdDate: string;
        readonly floorPricePercentChange?: number | null;
        readonly isVerified: boolean;
        readonly logo: string | null;
        readonly name: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"StatsHomepageCollectionCell_collection" | "StatsTableHomepageRowData" | "StatsTableHomepageRowLiveData" | "collection_url">;
      } | null;
    } | null>;
  };
  readonly trendingCollections?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdDate: string;
        readonly floorPricePercentChange?: number | null;
        readonly isVerified: boolean;
        readonly logo: string | null;
        readonly name: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"StatsHomepageCollectionCell_collection" | "StatsTableHomepageRowData" | "StatsTableHomepageRowLiveData" | "collection_url">;
      } | null;
    } | null>;
  };
  readonly trendingCollectionsByCategory?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdDate: string;
        readonly floorPricePercentChange?: number | null;
        readonly isVerified: boolean;
        readonly logo: string | null;
        readonly name: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"StatsHomepageCollectionCell_collection" | "StatsTableHomepageRowData" | "StatsTableHomepageRowLiveData" | "collection_url">;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "HomePageStatsTables_data";
};
export type HomePageStatsTables_data$key = {
  readonly " $data"?: HomePageStatsTables_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageStatsTables_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "categories",
  "variableName": "categoriesV2"
},
v1 = {
  "kind": "Variable",
  "name": "chains",
  "variableName": "chain"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eth",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isVerified",
            "storageKey": null
          },
          {
            "condition": "floorPricePercentChange",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "statsTimeWindow",
                    "variableName": "statsTimeWindow"
                  }
                ],
                "kind": "ScalarField",
                "name": "floorPricePercentChange",
                "storageKey": null
              }
            ]
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StatsHomepageCollectionCell_collection"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "collection_url",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCategory",
                "storageKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": ([]/*: any*/)
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "StatsTableHomepageRowData",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionStatsV2Type",
                "kind": "LinkedField",
                "name": "statsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thirtyDayChange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "thirtyDayVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "totalVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": ([]/*: any*/)
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "StatsTableHomepageRowLiveData",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionStatsV2Type",
                "kind": "LinkedField",
                "name": "statsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "floorPrice",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oneHourChange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "oneHourVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sixHourChange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "sixHourVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oneDayChange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "oneDayVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sevenDayChange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "sevenDayVolume",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "args": null,
            "argumentDefinitions": ([]/*: any*/)
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "trendingCollectionsSortBy"
},
v10 = {
  "kind": "Literal",
  "name": "topCollectionLimit",
  "value": 500
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categories"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoriesV2"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "floorPricePercentChange"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fromHomePage"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCategory"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "statsTimeWindow"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "topCollectionsSortBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "trendingCollectionsSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePageStatsTables_data",
  "selections": [
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "topCollectionsSortBy"
        }
      ],
      "concreteType": "CollectionTypeConnection",
      "kind": "LinkedField",
      "name": "topCollectionsByCategory",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "condition": "isCategory",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "CollectionTypeConnection",
          "kind": "LinkedField",
          "name": "trendingCollectionsByCategory",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isCategory",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "categories",
              "variableName": "categories"
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Variable",
              "name": "fromHomePage",
              "variableName": "fromHomePage"
            },
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "CollectionTypeConnection",
          "kind": "LinkedField",
          "name": "trendingCollections",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f3feb1baf88e223b9a98a1a3d0099d2d";

export default node;
