import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { useHomePageCollectionStatsEventSubscription } from "@/lib/graphql/__generated__/useHomePageCollectionStatsEventSubscription.graphql"

export const useHomePageCollectionStatsEvent = ({ slug }: { slug: string }) => {
  const config: GraphQLSubscriptionConfig<useHomePageCollectionStatsEventSubscription> =
    useMemo(
      () => ({
        variables: { slug: `hp.${slug}` },
        subscription: graphql`
          subscription useHomePageCollectionStatsEventSubscription(
            $slug: String!
          ) {
            homepageStatsEvent(slug: $slug) {
              collection {
                ...StatsTableHomepageRowLiveData
              }
            }
          }
        `,
      }),
      [slug],
    )
  useSubscription<useHomePageCollectionStatsEventSubscription>(config)
}
