/**
 * @generated SignedSource<<109da55346011ff968256394bb8290c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePageHeaderCarousel_data$data = ReadonlyArray<{
  readonly collection: {
    readonly logo: string | null;
    readonly name: string;
  };
  readonly overrideUrl: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderCollectionItemHomepage_data">;
  readonly " $fragmentType": "HomePageHeaderCarousel_data";
}>;
export type HomePageHeaderCarousel_data$key = ReadonlyArray<{
  readonly " $data"?: HomePageHeaderCarousel_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageHeaderCarousel_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HomePageHeaderCarousel_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overrideUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderCollectionItemHomepage_data"
    }
  ],
  "type": "HomePageHeaderCarouselType",
  "abstractKey": null
};

(node as any).hash = "e542fed83fcd01a2bb99ba4b50bd2075";

export default node;
