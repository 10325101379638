import { HomePageStatsTab } from "@/features/home/components/HomePageStats/types"
import { getTrackingFn } from "../utils"

export const trackStatsTableToggleTab = getTrackingFn<{
  previousTabName: HomePageStatsTab
  newTabName: HomePageStatsTab
}>("stats table toggle tab")

export const trackStatsTableSelectFilterOption = getTrackingFn<{
  currentTab: HomePageStatsTab
  previousFilterOption: string
  newFilterOption: string
}>("stats table set filter option")

export const trackStatsTableClickCollectionRow = getTrackingFn<{
  activeTab: HomePageStatsTab
  collectionSlug: string
  index: number
  sortBy: string
}>("stats table click collection row")

export const trackStatsTableChainFilterOption = getTrackingFn<{
  currentTab: HomePageStatsTab
  chain: string
}>("Home page stats chain filter selected")
