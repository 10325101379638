import React from "react"
import { Text, InlineFlex, VerticalAligned } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { useTranslate } from "@/hooks/useTranslate"
import {
  HeaderCollectionItemCategory_data$key,
  HeaderCollectionItemCategory_data$data,
} from "@/lib/graphql/__generated__/HeaderCollectionItemCategory_data.graphql"
import {
  HeaderCollectionItemHomepage_data$data,
  HeaderCollectionItemHomepage_data$key,
} from "@/lib/graphql/__generated__/HeaderCollectionItemHomepage_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { bn, roundAboveMin } from "@/lib/helpers/numberUtils"

const NO_VALUE_PLACEHOLDER = "－"

type SharedProps = {
  index: number
  isPriorityImage?: boolean
  size?: number
}

type HomePageHeaderCollectionItemProps = SharedProps & {
  dataKey: HeaderCollectionItemHomepage_data$key | null
}

type CategoryPageHeaderCollectionItemProps = SharedProps & {
  dataKey: HeaderCollectionItemCategory_data$key | null
}

type HeaderCollectionItemProps = SharedProps & {
  data:
    | HeaderCollectionItemHomepage_data$data
    | HeaderCollectionItemCategory_data$data
}

export const HomePageHeaderCollectionItem = ({
  dataKey,
  ...rest
}: HomePageHeaderCollectionItemProps) => {
  const data = useFragment(
    graphql`
      fragment HeaderCollectionItemHomepage_data on HomePageHeaderCarouselType {
        collection {
          slug
          name
          logo
          ...collection_url
          verificationStatus
          statsV2 {
            floorPrice {
              unit
              symbol
            }
          }
        }
        shortDescription
        linkOverride
        overrideUrl
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  return <HeaderCollectionItem data={data} {...rest} />
}

export const CategoryPagePageHeaderCollectionItem = ({
  dataKey,
  ...rest
}: CategoryPageHeaderCollectionItemProps) => {
  const data = useFragment(
    graphql`
      fragment HeaderCollectionItemCategory_data on CategoryPageCarouselType {
        collection {
          slug
          name
          logo
          ...collection_url
          verificationStatus
          statsV2 {
            floorPrice {
              unit
              symbol
            }
          }
        }
        shortDescription
        overrideUrl
      }
    `,
    dataKey,
  )

  if (!data) {
    return null
  }

  return <HeaderCollectionItem data={data} {...rest} />
}

const HeaderCollectionItem = ({
  data,
  index,
  isPriorityImage = false,
  size,
}: HeaderCollectionItemProps) => {
  const t = useTranslate("home")

  const { collection, shortDescription, overrideUrl } = data
  const { linkOverride } = data as HeaderCollectionItemHomepage_data$data
  const { slug, name, logo, verificationStatus, statsV2 } = collection
  const collectionUrl = getCollectionUrl(collection)
  const imageUrl = overrideUrl ?? logo

  if (!imageUrl) {
    return null
  }

  const floorPrice =
    statsV2.floorPrice && !bn(statsV2.floorPrice.unit).isZero()
      ? roundAboveMin(bn(statsV2.floorPrice.unit), 2)
      : NO_VALUE_PLACEHOLDER

  const renderVerificationBadge = (
    <InlineFlex className="ml-1 align-middle">
      {verificationStatus === "VERIFIED" && <StyledVerifiedIcon size="small" />}
    </InlineFlex>
  )

  return (
    <Flex justifyContent="center">
      <Link
        eventParams={{ collectionSlug: slug, index }}
        eventSource="TopCarousel"
        href={linkOverride || collectionUrl}
      >
        <ImageContainer>
          <Image
            alt={name}
            height={size}
            objectFit="cover"
            priority={isPriorityImage}
            src={imageUrl}
            width={size}
          />
          <VerticalAligned className="absolute bottom-0 z-[1] gap-0.5 pb-3 pl-4">
            <Flex alignItems="center">
              <Overflow
                lines={1}
                overrides={{
                  Tooltip: {
                    content: (
                      <>
                        {name}
                        {renderVerificationBadge}
                      </>
                    ),
                  },
                }}
              >
                <Text
                  asChild
                  className="font-inter leading-[1]"
                  color="white"
                  size="medium"
                  weight="semibold"
                >
                  <h5>
                    {name}
                    {renderVerificationBadge}
                  </h5>
                </Text>
              </Overflow>
            </Flex>

            <span>
              {shortDescription && (
                <>
                  <Text.Body
                    className="text-white"
                    size="small"
                    weight="semibold"
                  >
                    {shortDescription}
                  </Text.Body>
                </>
              )}
              {shortDescription && floorPrice !== NO_VALUE_PLACEHOLDER && (
                <TextDot>&#183;</TextDot>
              )}
              {floorPrice !== NO_VALUE_PLACEHOLDER && (
                <Text.Body className="text-white" size="small">
                  {t(
                    "header.collectionItem.floorPrice",
                    "Floor: {{floorPrice}} {{assetSymbol}}",
                    {
                      floorPrice,
                      assetSymbol: statsV2.floorPrice?.symbol || "",
                    },
                  )}
                </Text.Body>
              )}
            </span>
          </VerticalAligned>
        </ImageContainer>
      </Link>
    </Flex>
  )
}

const TextDot = styled.span`
  color: white;
  margin: 0 5px;
`

const ImageContainer = styled(Flex)`
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: ${props => props.theme.borderRadius.large};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: ${props => props.theme.borderRadius.large};
  }

  img {
    border-radius: ${props => props.theme.borderRadius.large};
    z-index: -1;
    width: 100%;
    height: auto;
  }

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.08);
        transition-duration: 0.4s;
      }
    }
  }

  &:not(:hover) {
    img {
      transition: transform 0.4s;
    }
  }
`

const StyledVerifiedIcon = styled(VerifiedIcon)`
  margin-left: 4px;
  width: 15px;
  height: 15px;
`
