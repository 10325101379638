/**
 * @generated SignedSource<<99caa229a905591d0c644f847165fb86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatsTableHomepageRowData$data = {
  readonly statsV2: {
    readonly thirtyDayChange: number;
    readonly thirtyDayVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
    readonly totalVolume: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
    };
  };
  readonly " $fragmentType": "StatsTableHomepageRowData";
};
export type StatsTableHomepageRowData$key = {
  readonly " $data"?: StatsTableHomepageRowData$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatsTableHomepageRowData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "StatsTableHomepageRowData"
};

(node as any).hash = "5e755e1256ee9fcaf31554e8c1a5690a";

export default node;
