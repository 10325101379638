import React, { useCallback, useMemo, memo } from "react"
import { Media, useIsLessThanLg, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import {
  FeatureTable,
  FeatureTableRenderProps,
} from "@/design-system/FeatureTable"
import { SelectOption } from "@/design-system/Select"
import { SORT_BY_TO_TIME_WINDOW } from "@/features/rankings"
import {
  RANK_COLUMN_WIDTH_LARGE,
  RANK_COLUMN_WIDTH_SMALL,
  StatsTableHomepageRow,
  StatsTableHomepageSkeleton,
} from "@/features/stats/components/StatsTableHomepageRow"
import { useTranslate } from "@/hooks/useTranslate"
import { trackStatsTableClickCollectionRow } from "@/lib/analytics/events/homepageEvents"
import { HomePageStatsTables_data$data } from "@/lib/graphql/__generated__/HomePageStatsTables_data.graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { media } from "@/styles/styleUtils"
import { $nav_height } from "@/styles/variables"
import { HOME_MOCK_ITEMS } from "./constants"
import { HomePageStatsTab, HomepageRankingsRow } from "./types"
import { getSortedHomepageRows } from "./utils"

export type HomePageStatsColumnData =
  HomePageStatsTables_data$data["trendingCollections"]

type RankingsTableProps = {
  activeTab: HomePageStatsTab
  indexStart: 1 | 6
  isLoading: boolean
  sortableHeaders: JSX.Element[]
  tableKey?: string
  data: HomePageStatsColumnData | null
  sortBy: SelectOption<string>
  rowMaxWidth: number
  floorPricePercentChangeSupported: boolean
}

const COLLECTION_NAME_WIDTH_PERCENTAGE_LARGE = 50
const COLLECTION_NAME_WIDTH_PERCENTAGE_SMALL = 50

const ROW_PADDING_LARGE = 16
const ROW_PADDING_SMALL = 16

const LINE_ITEM_WIDTH_LARGE = 38
const LINE_ITEM_WIDTH_SMALL = 24

const _HomePageStats = ({
  activeTab,
  data,
  indexStart,
  isLoading,
  sortableHeaders,
  sortBy,
  tableKey,
  rowMaxWidth,
  floorPricePercentChangeSupported,
}: RankingsTableProps) => {
  const t = useTranslate("home")
  const headerProps = {
    top: $nav_height,
    overflowX: "auto",
    overflowY: "visible",
    padding: { _: "8px", xl: "16px" },
    borderColor: "border",
  } as const
  const isLarge = !useIsLessThanLg()
  const timeWindow = SORT_BY_TO_TIME_WINDOW.get(sortBy.value)
  const collectionCellMaxWidth = useMemo(() => {
    // This finds how large just the collection cell can be
    // by subtracting from the row width the padding and then
    // multiply by the width percentage
    // then subtracts the line width ex. 1. or 2.
    if (isLarge) {
      return (
        (rowMaxWidth - ROW_PADDING_LARGE) *
          (COLLECTION_NAME_WIDTH_PERCENTAGE_LARGE / 100) -
        LINE_ITEM_WIDTH_LARGE
      )
    } else {
      return (
        (rowMaxWidth - ROW_PADDING_SMALL) *
          (COLLECTION_NAME_WIDTH_PERCENTAGE_SMALL / 100) -
        LINE_ITEM_WIDTH_SMALL
      )
    }
  }, [isLarge, rowMaxWidth])

  const renderFullRow = useCallback(
    (props: FeatureTableRenderProps<HomepageRankingsRow>) => {
      if (isLoading) {
        return <StatsTableHomepageSkeleton cellHeight="96px" renderFloorPrice />
      }

      return (
        <StatsTableHomepageRow
          {...props}
          activeTab={activeTab}
          collectionCellMaxWidth={collectionCellMaxWidth}
          collectionCellWidth={`${COLLECTION_NAME_WIDTH_PERCENTAGE_LARGE}%`}
          floorPricePercentChangeSupported={floorPricePercentChangeSupported}
          indexStart={indexStart}
          sortBy={sortBy}
        />
      )
    },
    [
      activeTab,
      collectionCellMaxWidth,
      floorPricePercentChangeSupported,
      indexStart,
      isLoading,
      sortBy,
    ],
  )

  const renderPrimary = useCallback(
    (props: FeatureTableRenderProps<HomepageRankingsRow>) =>
      isLoading ? (
        <StatsTableHomepageSkeleton
          cellHeight="64px"
          renderFloorPrice={false}
        />
      ) : (
        <StatsTableHomepageRow
          {...props}
          activeTab={activeTab}
          collectionCellMaxWidth={collectionCellMaxWidth}
          collectionCellWidth={`${COLLECTION_NAME_WIDTH_PERCENTAGE_SMALL}%`}
          floorPricePercentChangeSupported={floorPricePercentChangeSupported}
          indexStart={indexStart}
          isSmallScreen
          sortBy={sortBy}
        />
      ),
    [
      activeTab,
      collectionCellMaxWidth,
      floorPricePercentChangeSupported,
      indexStart,
      isLoading,
      sortBy,
    ],
  )

  return (
    <Container>
      <FeatureTable
        getHref={({ data }) => (data.slug ? getCollectionUrl(data) : undefined)}
        header={
          <>
            <div>
              <Media greaterThanOrEqual="xl">
                <FeatureTable.HeaderContainer
                  className="mb-2 px-4 pb-3 pt-0"
                  {...headerProps}
                >
                  {sortableHeaders.map((header, index) => (
                    <FeatureTable.Cell
                      justifyContent={index >= 2 ? "flex-end" : "flex-start"}
                      key={index}
                      width={
                        index === 0
                          ? RANK_COLUMN_WIDTH_LARGE
                          : index === 1
                          ? "50%"
                          : "20%"
                      }
                    >
                      {header}
                    </FeatureTable.Cell>
                  ))}
                </FeatureTable.HeaderContainer>
              </Media>
            </div>
            <div>
              <Media lessThan="xl">
                <FeatureTable.HeaderContainer className="mb-2 px-2 pb-3 pt-1">
                  <FeatureTable.Cell
                    justifyContent="flex-start"
                    width={RANK_COLUMN_WIDTH_SMALL}
                  >
                    <StatsHeaderLabel>
                      {t("statsTable.collectionHeaderMobileRank", "#")}
                    </StatsHeaderLabel>
                  </FeatureTable.Cell>
                  <FeatureTable.Cell
                    flexGrow={1}
                    justifyContent="flex-start"
                    minWidth="70%"
                  >
                    <StatsHeaderLabel>
                      {t("statsTable.collectionHeader", "Collection")}
                    </StatsHeaderLabel>
                  </FeatureTable.Cell>
                  <FeatureTable.Cell justifyContent="flex-end" width="20%">
                    <StatsHeaderLabel>
                      {t("statsTable.volumeHeader", "Volume")}
                    </StatsHeaderLabel>
                  </FeatureTable.Cell>
                </FeatureTable.HeaderContainer>
              </Media>
            </div>
          </>
        }
        itemHeightEstimate={90}
        items={
          isLoading || !data
            ? HOME_MOCK_ITEMS
            : getSortedHomepageRows(
                data,
                timeWindow,
                activeTab === "top",
                indexStart,
              )
        }
        key={tableKey}
        overscanBy={Number.POSITIVE_INFINITY}
        renderFullRow={renderFullRow}
        renderMore={() => <></>}
        renderPrimary={renderPrimary}
        role="table"
        showBorder={false}
        showInteractiveStyles
        onClick={props =>
          trackStatsTableClickCollectionRow({
            activeTab,
            collectionSlug: props.data.slug,
            index: props.index,
            sortBy: sortBy.value,
          })
        }
      />
    </Container>
  )
}

export const HomePageStats = memo(_HomePageStats)

const Container = styled(Block)`
  a {
    width: 100%;

    ${media({
      xs: css`
        padding: 12px 8px;
      `,
      xl: css`
        padding: 12px 16px;
      `,
    })}
  }
`

const StatsHeaderLabel = styled(Text.Body).attrs({
  size: "small",
  className: "text-secondary",
})``
