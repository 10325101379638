import React, { ComponentProps } from "react"
import { Media, Skeleton } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"

export const HomePageCarouselSkeleton = () => {
  return (
    <Block alignSelf="center" className="w-full">
      <Media greaterThanOrEqual="xxxl">
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(6)].map((_, i) => (
              <Skeleton.Block className="h-[300px] rounded-2xl" key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media between={["lg", "xxxl"]}>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(4)].map((_, i) => (
              <Skeleton.Block className="h-[300px] rounded-2xl" key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media between={["md", "lg"]}>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(3)].map((_, i) => (
              <Skeleton.Block className="h-[300px] rounded-2xl" key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media between={["sm", "md"]}>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(2)].map((_, i) => (
              <Skeleton.Block className="h-[300px] rounded-2xl" key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media lessThan="sm">
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(1)].map((_, i) => (
              <Skeleton.Block className="h-[300px] rounded-2xl" key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>
    </Block>
  )
}

const SkeletonRow = ({
  children,
  ...props
}: ComponentProps<typeof Skeleton.Row>) => (
  <Skeleton.Row className="w-full gap-2.5" {...props}>
    {children}
  </Skeleton.Row>
)
