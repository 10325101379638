import React, { useMemo } from "react"
import {
  Media,
  useIsLessThanLg,
  Text,
  InlineFlex,
  FlexColumn,
  classNames,
  FlexColumnProps,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { CollectionAssetsPreviewTooltip } from "@/components/collections/CollectionAssetsPreviewTooltip.react"
import { Overflow } from "@/components/common/Overflow"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { useIsHomePageStatsCollectionHoverPreviewEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { StatsHomepageCollectionCell_collection$key } from "@/lib/graphql/__generated__/StatsHomepageCollectionCell_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { resizeImage } from "@/lib/helpers/urls"
import { PLACEHOLDER_IMAGE } from "../../../../constants"

type StatsHomepageCollectionCellProps = {
  collection: StatsHomepageCollectionCell_collection$key | null
  subtitle?: JSX.Element
  collectionCellMaxWidth: number
}

const IMAGE_WIDTH_LARGE = 64
const IMAGE_WIDTH_SMALL = 48

const IMAGE_PADDING = 8

const COLLECTION_NAME_PADDING_LARGE = 24
const COLLECTION_NAME_PADDING_SMALL = 10

export const StatsHomepageCollectionCell = ({
  collection: collectionKey,
  subtitle,
  collectionCellMaxWidth,
}: StatsHomepageCollectionCellProps) => {
  const isHoverPreviewEnabled =
    useIsHomePageStatsCollectionHoverPreviewEnabled()
  const t = useTranslate("statsV2")
  const collection = useFragment(
    graphql`
      fragment StatsHomepageCollectionCell_collection on CollectionType {
        name
        logo
        isVerified
        slug
      }
    `,
    collectionKey,
  )
  const isLarge = !useIsLessThanLg()

  const collectionNameMaxWidth = useMemo(() => {
    // Calculate max width for the collection name
    // This subtracts from collectionCellMaxWidth and subtracts image and padding
    if (isLarge) {
      return (
        collectionCellMaxWidth -
        IMAGE_WIDTH_LARGE -
        IMAGE_PADDING -
        COLLECTION_NAME_PADDING_LARGE
      )
    } else {
      return (
        collectionCellMaxWidth -
        IMAGE_WIDTH_SMALL -
        IMAGE_PADDING -
        COLLECTION_NAME_PADDING_SMALL
      )
    }
  }, [isLarge, collectionCellMaxWidth])

  if (!collection) {
    return null
  }

  const name = collection.name
  const isVerified = collection.isVerified

  const collectionName = (
    <>
      <Media greaterThanOrEqual="md">
        <Text.Body weight="semibold">
          <Flex alignItems="center" maxWidth={`${collectionNameMaxWidth}px`}>
            <Overflow>{name}</Overflow>
            {isVerified && (
              <InlineFlex className="ml-1 align-middle">
                <VerifiedIcon size="small" />
              </InlineFlex>
            )}
          </Flex>
        </Text.Body>
      </Media>
      <Media lessThan="md">
        <Text.Body size="small" weight="semibold">
          <Flex alignItems="center" maxWidth={`${collectionNameMaxWidth}px`}>
            <Overflow>{name}</Overflow>
            {isVerified && (
              <InlineFlex className="ml-1 align-middle">
                <VerifiedIcon size="small" />
              </InlineFlex>
            )}
          </Flex>
        </Text.Body>
      </Media>
    </>
  )

  const collectionImage = (imageSize: number) => {
    const image = (
      <StatsCollectionImageContainer
        style={{ height: imageSize + 8, width: imageSize + 8 }}
      >
        <Image
          alt={t("stats.homepage.collectionImage.altText", "Collection Image")}
          height={imageSize}
          layout="fill"
          objectFit="cover"
          src={
            collection.logo
              ? resizeImage(collection.logo, {
                  size: 136,
                  freezeAnimation: true,
                })
              : PLACEHOLDER_IMAGE
          }
          unoptimized
        />
      </StatsCollectionImageContainer>
    )

    return isHoverPreviewEnabled ? (
      <CollectionAssetsPreviewTooltip
        placement="top-start"
        slug={collection.slug}
      >
        {image}
      </CollectionAssetsPreviewTooltip>
    ) : (
      image
    )
  }

  return (
    <>
      <Media lessThan="sm">
        <StatsContainer alignItems="center" justifyContent="center">
          {collectionImage(IMAGE_WIDTH_SMALL)}
          <TextContainer
            className="pl-2.5"
            style={{
              maxWidth: `${collectionNameMaxWidth}px`,
            }}
          >
            {collectionName}
            {subtitle}
          </TextContainer>
        </StatsContainer>
      </Media>
      <Media between={["sm", "lg"]}>
        <StatsContainer alignItems="center" justifyContent="center">
          {collectionImage(IMAGE_WIDTH_SMALL)}
          <TextContainer className="pl-2.5">
            {collectionName}
            {subtitle}
          </TextContainer>
        </StatsContainer>
      </Media>
      <Media greaterThanOrEqual="lg">
        <StatsContainer alignItems="center" justifyContent="center">
          {collectionImage(IMAGE_WIDTH_LARGE)}
          <TextContainer className="pl-6">
            {collectionName}
            {subtitle}
          </TextContainer>
        </StatsContainer>
      </Media>
    </>
  )
}

const TextContainer = ({ className, ...props }: FlexColumnProps) => (
  <FlexColumn
    className={classNames("items-start self-center", className)}
    {...props}
  />
)

const StatsContainer = styled(Flex)`
  color: inherit;

  :hover {
    color: inherit;
  }
`

const StatsCollectionImageContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.components.border.level1};
  border-radius: ${props => props.theme.borderRadius.button};
  flex: none;
  overflow: hidden;
  position: relative;
`
