import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { Link, LinkProps } from "@/components/common/Link"
import {
  CardBanner,
  CardBannerContainer,
  CardContainer,
  CardFooterContainer,
  CardSection,
  CardTitleContainer,
} from "../HomePageShelf/HomePageCollectionCard.react"

export type HomePageSimpleCardProps = {
  imageUrl: string
  href: string
  title: string
  shortTitle?: boolean
} & Pick<LinkProps, "eventParams" | "eventSource">

export const HomePageSimpleCard = ({
  title,
  imageUrl,
  href,
  shortTitle = false,
  eventSource,
  eventParams,
}: HomePageSimpleCardProps) => {
  return (
    <CardContainer>
      <Link eventParams={eventParams} eventSource={eventSource} href={href}>
        <CardSection>
          <CardBannerContainer>
            <CardBanner
              height={400}
              layout="fill"
              objectFit="cover"
              src={imageUrl}
            />
          </CardBannerContainer>
          <CardFooterContainer alignItems="center">
            <CardTitleContainer>
              <Text
                asChild
                className={classNames(
                  "items-center truncate text-left font-inter",
                  "[font-variant-ligatures:no-contextual]",
                  shortTitle ? "h-6" : "h-12",
                )}
                color="primary"
                responsive
                size="medium"
                weight="semibold"
              >
                <div>{title}</div>
              </Text>
            </CardTitleContainer>
          </CardFooterContainer>
        </CardSection>
      </Link>
    </CardContainer>
  )
}
