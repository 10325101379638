import styled, { css } from "styled-components"
import { Grid } from "@/design-system/Grid/Grid.react"
import { media } from "@/styles/styleUtils"

export const StyledGridHScrollContainer = styled(Grid)`
  ${media({
    xs: css`
      grid-row-gap: 8px;
    `,
    xl: css`
      grid-row-gap: 16px;
    `,
    xxl: css`
      grid-row-gap: 24px;
    `,
  })}
`
