/**
 * @generated SignedSource<<4e10a016d9606229071799980c59ba4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryScroller_collections$data = ReadonlyArray<{
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"HomePageCollectionCard_data">;
  readonly " $fragmentType": "CategoryScroller_collections";
}>;
export type CategoryScroller_collections$key = ReadonlyArray<{
  readonly " $data"?: CategoryScroller_collections$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryScroller_collections">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CategoryScroller_collections",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "timeWindow",
          "value": "ONE_DAY"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HomePageCollectionCard_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "e537f463ec034a20cdcb5ec2f4a1d96c";

export default node;
