import React from "react"
import { Flex, Media, Text } from "@opensea/ui-kit"
import { Grid } from "@/design-system/Grid/Grid.react"
import { useTranslate } from "@/hooks/useTranslate"

export const BrowseCategoriesError = () => {
  const t = useTranslate("home")
  const sectionTitle = t(
    "browseCategories.exploreCategories",
    "Explore Categories",
  )
  const errorText = t(
    "browseCategories.error.description",
    "There has been an issue fetching this data. Try to refresh.",
  )

  return (
    <>
      <Grid.Item marginBottom={{ _: "8px", xxl: "20px" }}>
        <Media lessThan="lg">
          <Text.Heading size="small">{sectionTitle}</Text.Heading>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Media greaterThanOrEqual="xxl">
            <Text.Heading size="large">{sectionTitle}</Text.Heading>
          </Media>
          <Media between={["lg", "xxl"]}>
            <Text.Heading size="medium">{sectionTitle}</Text.Heading>
          </Media>
        </Media>
      </Grid.Item>
      <Grid.Item>
        <Flex className="items-center text-center">
          <Text.Body className="mr-5" size="medium">
            {errorText}
          </Text.Body>
        </Flex>
      </Grid.Item>
    </>
  )
}
