/**
 * @generated SignedSource<<d9585965ab821261bc31aef5058e573c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHomePageCollectionStatsEventSubscription$variables = {
  slug: string;
};
export type useHomePageCollectionStatsEventSubscription$data = {
  readonly homepageStatsEvent: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"StatsTableHomepageRowLiveData">;
    };
  };
};
export type useHomePageCollectionStatsEventSubscription = {
  response: useHomePageCollectionStatsEventSubscription$data;
  variables: useHomePageCollectionStatsEventSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eth",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "floorPrice",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oneHourChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "oneHourVolume",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sixHourChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "sixHourVolume",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oneDayChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "oneDayVolume",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sevenDayChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "sevenDayVolume",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHomePageCollectionStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HomepageStatsEvent",
        "kind": "LinkedField",
        "name": "homepageStatsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "StatsTableHomepageRowLiveData",
                "selections": [
                  (v6/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHomePageCollectionStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HomepageStatsEvent",
        "kind": "LinkedField",
        "name": "homepageStatsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "678ebe3ba98ac51cbc6176b3f792dd5a",
    "id": null,
    "metadata": {},
    "name": "useHomePageCollectionStatsEventSubscription",
    "operationKind": "subscription",
    "text": "subscription useHomePageCollectionStatsEventSubscription(\n  $slug: String!\n) {\n  homepageStatsEvent(slug: $slug) {\n    collection {\n      ...StatsTableHomepageRowLiveData\n      id\n    }\n  }\n}\n\nfragment StatsTableHomepageRowLiveData on CollectionType {\n  statsV2 {\n    floorPrice {\n      unit\n      eth\n      symbol\n    }\n    oneHourChange\n    oneHourVolume {\n      eth\n      unit\n      symbol\n    }\n    sixHourChange\n    sixHourVolume {\n      eth\n      unit\n      symbol\n    }\n    oneDayChange\n    oneDayVolume {\n      eth\n      unit\n      symbol\n    }\n    sevenDayChange\n    sevenDayVolume {\n      eth\n      unit\n      symbol\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74dc9e86cb0974b6cb5ab93602b787f0";

export default node;
