import React from "react"
import { ToggleButtonGroup, SpaceBetween } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { HomePageStatsTab } from "../HomePageStats/types"

type HomepageStatsTabNavigationProps = {
  currentTab: HomePageStatsTab
  endEnhancer?: JSX.Element
  handleClick: (id: HomePageStatsTab) => unknown
  tabs: {
    label: string
    id: HomePageStatsTab
  }[]
}

export const HomepageStatsTabNavigation = ({
  currentTab,
  endEnhancer,
  handleClick,
  tabs,
}: HomepageStatsTabNavigationProps) => {
  return (
    <Flex className="w-full">
      <Block as="nav" className="w-full">
        <SpaceBetween className="items-end">
          <Block>
            <ToggleButtonGroup value={currentTab} onValueChange={handleClick}>
              {tabs.map(tab => {
                if (Array.isArray(tab)) {
                  return null
                }

                return (
                  <ToggleButtonGroup.Button key={tab.id} value={tab.id}>
                    {tab.label}
                  </ToggleButtonGroup.Button>
                )
              })}
            </ToggleButtonGroup>
          </Block>
          {endEnhancer}
        </SpaceBetween>
      </Block>
    </Flex>
  )
}
