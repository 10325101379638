import React, { useMemo, Dispatch, SetStateAction, useCallback } from "react"
import { Media, useIsLessThanLg } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Select, SelectOption } from "@/design-system/Select"
import { getRankingsPageHref } from "@/features/rankings/components/RankingsPage/utils"
import { RankingSelect } from "@/features/rankings/components/RankingsSelect"
import { getSmallChainOptions } from "@/hooks/useChains/useChainOptions"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackStatsTableChainFilterOption,
  trackStatsTableSelectFilterOption,
  trackStatsTableToggleTab,
} from "@/lib/analytics/events/homepageEvents"
import { HomepageStatsTabNavigation } from "../HomepageStatsTabNavigation"
import { useTabs } from "./constants"
import { HomePageStatsTab } from "./types"

type Props = {
  currentTab: HomePageStatsTab
  sortBy: SelectOption
  chain: SelectOption
  chainOptions: SelectOption[]
  sortByTimeOptions: SelectOption[]
  trendingSortByTimeOptions: SelectOption[]
  setSortBy: Dispatch<SetStateAction<SelectOption<string>>>
  setCurrentTab: Dispatch<SetStateAction<HomePageStatsTab>>
  setChain: Dispatch<SetStateAction<SelectOption>>
  category: string | undefined
}

export const HomePageStatsTableTabs = ({
  currentTab,
  sortBy,
  chain,
  chainOptions,
  sortByTimeOptions,
  trendingSortByTimeOptions,
  setSortBy,
  setCurrentTab,
  setChain,
  category,
}: Props) => {
  const isLg = !useIsLessThanLg()
  const t = useTranslate("home")
  const COLLECTION_STATS_TABS = useTabs()
  const chainOptionsSmall = useMemo(
    () => getSmallChainOptions(chainOptions),
    [chainOptions],
  )
  const viewAllButtonText = t("statsTable.viewAllButton", "View all")
  const isTrendingTab = currentTab === "trending"
  const href = getRankingsPageHref(
    currentTab,
    sortBy.value,
    chain.value,
    category,
  )

  const trackAndSetChain = useCallback(
    (chain: SelectOption) => {
      setChain(chain)
      trackStatsTableChainFilterOption({ currentTab, chain: chain.value })
    },
    [setChain, currentTab],
  )

  const handleTabChange = useCallback(
    (id?: string) => {
      if (id) {
        setCurrentTab(id as HomePageStatsTab)
        trackStatsTableToggleTab({
          previousTabName: currentTab,
          newTabName: id as HomePageStatsTab,
        })

        if (id === "trending") {
          const trendingSortBy = trendingSortByTimeOptions.find(
            option => option.value === sortBy.value,
          )

          if (!trendingSortBy) {
            setSortBy(
              trendingSortByTimeOptions[trendingSortByTimeOptions.length - 1],
            )
          }
        }
      }
    },
    [
      setCurrentTab,
      currentTab,
      trendingSortByTimeOptions,
      sortBy.value,
      setSortBy,
    ],
  )

  const timeOptionsSelect = (
    <Select
      clearable={false}
      maxHeight="370px"
      options={isTrendingTab ? trendingSortByTimeOptions : sortByTimeOptions}
      overrides={{
        Dropdown: {
          props: {
            popperOptions: { strategy: "fixed" },
          },
        },
        ContentItem: {
          props: {
            className: "h-9 lg:h-12 pt-2 pr-2 pb-2 pl-3 lg:pr-4 pl-4",
          },
        },
        ContentLabel: {
          props: {
            className: "mr-0.5 lg:mr-1",
          },
        },
        ContentLabelTitle: {
          props: {
            responsive: true,
          },
        },
      }}
      readOnly
      value={sortBy.value}
      variant="item"
      onSelect={(newValue: SelectOption | undefined) => {
        if (newValue && newValue !== sortBy) {
          setSortBy(newValue)
          trackStatsTableSelectFilterOption({
            currentTab,
            previousFilterOption: sortBy.value,
            newFilterOption: newValue.value,
          })
        }
      }}
    />
  )

  return (
    <>
      <Media greaterThanOrEqual="md">
        <HomepageStatsTabNavigation
          currentTab={currentTab}
          endEnhancer={
            <Flex alignItems="center" flexShrink={0}>
              <Flex>
                <Block marginRight={isLg ? "16px" : "8px"}>
                  <RankingSelect
                    key={isTrendingTab ? "trending-tab" : "top-tab"}
                    options={
                      isTrendingTab
                        ? trendingSortByTimeOptions
                        : sortByTimeOptions
                    }
                    setValue={(newValue: SelectOption | undefined) => {
                      if (newValue && newValue !== sortBy) {
                        setSortBy(newValue)
                        trackStatsTableSelectFilterOption({
                          currentTab,
                          previousFilterOption: sortBy.value,
                          newFilterOption: newValue.value,
                        })
                      }
                    }}
                    testId="Home--time-select"
                    useTabs
                    value={sortBy}
                  />
                </Block>
                <Block marginRight={isLg ? "16px" : "8px"}>
                  <RankingSelect
                    options={isLg ? chainOptions : chainOptionsSmall}
                    setValue={trackAndSetChain}
                    testId="Home--chain-dropdown"
                    value={chain}
                  />
                </Block>
                <Media greaterThanOrEqual="lg">
                  <Link
                    eventParams={{ currentTab, sortBy: sortBy.value }}
                    eventSource="TopTrendingList"
                    href={href}
                  >
                    <Button variant="secondary">{viewAllButtonText}</Button>
                  </Link>
                </Media>
                <Media lessThan="lg">
                  <Link
                    eventParams={{ currentTab, sortBy: sortBy.value }}
                    eventSource="TopTrendingList"
                    href={href}
                  >
                    <Button size="small" variant="secondary">
                      {viewAllButtonText}
                    </Button>
                  </Link>
                </Media>
              </Flex>
            </Flex>
          }
          handleClick={handleTabChange}
          tabs={COLLECTION_STATS_TABS}
        />
      </Media>
      <Media lessThan="md">
        <HomepageStatsTabNavigation
          currentTab={currentTab}
          endEnhancer={
            <Flex alignItems="center" flexShrink={0}>
              <Link
                eventParams={{ currentTab, sortBy: sortBy.value }}
                eventSource="TopTrendingList"
                href={href}
              >
                <Button size="small" variant="secondary">
                  {viewAllButtonText}
                </Button>
              </Link>
            </Flex>
          }
          handleClick={(id?: string) => {
            if (id) {
              setCurrentTab(id as HomePageStatsTab)
              trackStatsTableToggleTab({
                previousTabName: currentTab,
                newTabName: id as HomePageStatsTab,
              })
            }
          }}
          tabs={COLLECTION_STATS_TABS}
        />
        <Flex
          flex="0 0 100%"
          flexShrink={0}
          paddingBottom="8px"
          paddingTop="16px"
        >
          <Block marginRight="8px" width="50%">
            <RankingSelect
              className="w-full"
              includeTabLabelTooltip={isLg}
              options={isLg ? chainOptions : chainOptionsSmall}
              setValue={trackAndSetChain}
              testId="Home--chain-dropdown-small"
              useTabs
              value={chain}
            />
          </Block>
          <Block width="50%">{timeOptionsSelect}</Block>
        </Flex>
      </Media>
    </>
  )
}
