/* eslint-disable tailwindcss/no-custom-classname */
import React, { useCallback } from "react"
import {
  Media,
  useIsLessThanLg,
  useIsLessThanSm,
  useIsLessThanXXXl,
} from "@opensea/ui-kit"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import {
  Carousel,
  ResponsiveConfig,
  AutoplayConfig,
  BaseSlide,
} from "@/design-system/Carousel"
import { useIsMounted } from "@/hooks/useIsMounted"

export type HomePageCarouselSlide = {
  id: string
  collection: React.ReactElement
}

type HomePageCarouselProps = {
  handleSlideIndexChange?: (str: string) => void
  slides: HomePageCarouselSlide[]
  responsiveConfig: ResponsiveConfig
  testId: string
  autoplayConfig?: AutoplayConfig
  id: string
  autoComputeLoopedSlides?: boolean
  loop?: boolean
}

export const HomePageCarousel: React.ElementType<HomePageCarouselProps> = ({
  handleSlideIndexChange,
  responsiveConfig,
  slides,
  testId,
  autoplayConfig,
  id,
  autoComputeLoopedSlides,
  loop = true,
}: HomePageCarouselProps) => {
  const isMounted = useIsMounted()
  const isMobile = useIsLessThanSm()
  const isLarge = !useIsLessThanLg()
  const isXXXL = !useIsLessThanXXXl()

  let loopedSlides = 6
  if (isMobile) {
    loopedSlides = 1
  }
  if (isLarge) {
    loopedSlides = 4
  }
  if (isXXXL) {
    loopedSlides = 6
  }

  const renderSlide = useCallback((slide: BaseSlide) => {
    return slide.collection
  }, [])

  /**
   * We need to perform this check here as well as in the HomePageHeaderCarousel
   * in order to prevent strange behavior with Swiper. SSR causes some funky
   * issues with the library.
   */
  if (!isMounted) {
    return null
  }

  const handleActiveIndexChange = (index: number): void => {
    const data = slides[index].collection.props.dataKey
    if (handleSlideIndexChange) {
      if (data?.overrideUrl) {
        handleSlideIndexChange(data.overrideUrl)
      } else {
        handleSlideIndexChange(data?.collection.logo || "")
      }
    }
  }

  return (
    <Block
      alignSelf="center"
      data-testid={testId}
      marginX={{ _: "-34px", sm: "0px" }}
    >
      <Media lessThan="sm">
        <StyledCarousel
          autoplayConfig={autoplayConfig}
          centeredSlides
          className="home_header_swiper_mobile"
          enableArrowControls={false}
          enableFreeScroll
          enableMousewheel
          handleActiveIndexChange={handleActiveIndexChange}
          id={`${id}-mobile`}
          renderSlide={renderSlide}
          responsiveConfig={responsiveConfig}
          showScrollbar={false}
          slides={slides}
          speed={500}
          variant="spotlight"
        />
      </Media>
      <Media greaterThanOrEqual="sm">
        <StyledCarousel
          autoplayConfig={autoplayConfig}
          className="home_header_swiper"
          enableArrowControls
          enableFreeScroll
          enableMousewheel
          handleActiveIndexChange={handleActiveIndexChange}
          id={id}
          loop={loop}
          loopedSlides={autoComputeLoopedSlides ? undefined : loopedSlides}
          renderSlide={renderSlide}
          responsiveConfig={responsiveConfig}
          showScrollbar={false}
          slides={slides}
          speed={500}
          variant="spotlight"
        />
      </Media>
    </Block>
  )
}

const StyledCarousel = styled(Carousel)`
  border-radius: ${props => props.theme.borderRadius.large};
`
