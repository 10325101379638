import React from "react"
import { Media, Text, Container, Flex } from "@opensea/ui-kit"
import { graphql } from "react-relay"
import { Link } from "@/components/common/Link"
import { StyledGridHScrollContainer } from "@/components/common/StyledGridHScrollContainer"
import { Button } from "@/design-system/Button"
import { Grid } from "@/design-system/Grid/Grid.react"
import { PreSeededMaverickCategoryType } from "@/features/category/utils"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import { TrendingCategoryListQuery } from "@/lib/graphql/__generated__/TrendingCategoryListQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { useCategoryNameGetter } from "../collection-edit/categoryUtils"
import { CategoryScroller } from "./CategoryScroller.react"

type TrendingCategoryListProps = {
  categories: PreSeededMaverickCategoryType[]
}

export const TrendingCategoryList = ({
  categories,
}: TrendingCategoryListProps) => {
  const t = useTranslate("home")
  const categoryNameGetter = useCategoryNameGetter()

  const [categoryCollections] =
    useNoSuspenseLazyLoadQuery<TrendingCategoryListQuery>(
      graphql`
        query TrendingCategoryListQuery($categories: [CategoryV2Slug!]!) {
          trendingCollectionsByCategoryBatched(
            categories: $categories
            first: 42
            perCategoryLimit: 7
          ) {
            edges {
              node {
                ...CategoryScroller_collections
                category {
                  slug
                }
              }
            }
          }
        }
      `,
      { categories: categories.map(c => c.slug) },
    )

  return (
    <StyledGridHScrollContainer>
      {categories.map(category => {
        const collections = categoryCollections
          ? getNodes(
              categoryCollections.trendingCollectionsByCategoryBatched,
            ).filter(collection => collection.category?.slug === category.slug)
          : null

        if (!collections || collections.length === 0) {
          return <></>
        }

        return (
          <Grid.Item key={category.slug} xs={12}>
            {/* Overflow hidden used on Container component for Carousel "card" variants that require a visible overflow */}
            <Container className="overflow-x-hidden">
              <Flex className="items-center justify-between pb-3 lg:pb-4">
                <Media greaterThanOrEqual="lg">
                  <Text.Heading className="mr-2" size="medium">
                    {t(
                      "trendingCategoryList.title",
                      "Trending in {{ categoryName }}",
                      { categoryName: categoryNameGetter(category.slug) },
                    )}
                  </Text.Heading>
                </Media>
                <Media lessThan="lg">
                  <Text.Heading className="mr-2" size="small">
                    {t(
                      "trendingCategoryList.title",
                      "Trending in {{ categoryName }}",
                      { categoryName: categoryNameGetter(category.slug) },
                    )}
                  </Text.Heading>
                </Media>
                <Link href={`/category/${category.slug}`}>
                  <>
                    <Media lessThan="sm">
                      <Button size="small" variant="secondary">
                        {t("trendingCategoryList.viewAll", "View all")}
                      </Button>
                    </Media>
                    <Media between={["sm", "lg"]}>
                      <Button size="small" variant="secondary">
                        {t(
                          "trendingCategoryList.viewCategory",
                          "View category",
                        )}
                      </Button>
                    </Media>
                    <Media greaterThanOrEqual="lg">
                      <Button variant="secondary">
                        {t(
                          "trendingCategoryList.viewCategory",
                          "View category",
                        )}
                      </Button>
                    </Media>
                  </>
                </Link>
              </Flex>
              <CategoryScroller
                category={category.slug}
                collections={collections}
              />
            </Container>
          </Grid.Item>
        )
      })}
    </StyledGridHScrollContainer>
  )
}
