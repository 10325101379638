/**
 * @generated SignedSource<<ea1f0ad7ef4477b6ec11e9d583e8d544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrendingCategoryListQuery$variables = {
  categories: ReadonlyArray<string>;
};
export type TrendingCategoryListQuery$data = {
  readonly trendingCollectionsByCategoryBatched: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly category: {
          readonly slug: string;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"CategoryScroller_collections">;
      } | null;
    } | null>;
  };
};
export type TrendingCategoryListQuery = {
  response: TrendingCategoryListQuery$data;
  variables: TrendingCategoryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categories"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 42
  },
  {
    "kind": "Literal",
    "name": "perCategoryLimit",
    "value": 7
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrendingCategoryListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "trendingCollectionsByCategoryBatched",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CategoryScroller_collections"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrendingCategoryListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "trendingCollectionsByCategoryBatched",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relayId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionStatsV2Type",
                    "kind": "LinkedField",
                    "name": "statsV2",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSupply",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "dropv2",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mintedItemCount",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "Drop721LimitedEditionType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "Drop1155LimitedEditionType",
                        "abstractKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "statsTimeWindow",
                        "value": "ONE_DAY"
                      }
                    ],
                    "concreteType": "WindowCollectionStatsType",
                    "kind": "LinkedField",
                    "name": "windowCollectionStats",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "floorPrice",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "volume",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "windowCollectionStats(statsTimeWindow:\"ONE_DAY\")"
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17523b596b0e25c04e8506c03dbda5f8",
    "id": null,
    "metadata": {},
    "name": "TrendingCategoryListQuery",
    "operationKind": "query",
    "text": "query TrendingCategoryListQuery(\n  $categories: [CategoryV2Slug!]!\n) {\n  trendingCollectionsByCategoryBatched(categories: $categories, first: 42, perCategoryLimit: 7) {\n    edges {\n      node {\n        ...CategoryScroller_collections\n        category {\n          slug\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment CategoryScroller_collections on CollectionType {\n  relayId\n  ...HomePageCollectionCard_data_3C7EJl\n}\n\nfragment HomePageCollectionCardFooter_data_3C7EJl on CollectionType {\n  windowCollectionStats(statsTimeWindow: ONE_DAY) {\n    floorPrice {\n      unit\n      symbol\n    }\n    volume {\n      unit\n      symbol\n    }\n  }\n}\n\nfragment HomePageCollectionCard_data_3C7EJl on CollectionType {\n  banner\n  name\n  verificationStatus\n  ...useDropState_data\n  ...HomePageCollectionCardFooter_data_3C7EJl\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useDropState_data on CollectionType {\n  statsV2 {\n    totalSupply\n  }\n  dropv2 {\n    __typename\n    stages {\n      __typename\n      startTime\n      endTime\n      id\n    }\n    mintedItemCount\n    ... on Drop721LimitedEditionType {\n      totalItemCount\n    }\n    ... on Drop1155LimitedEditionType {\n      totalItemCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "da71e8472ebbec27ad1938decdcf5ef2";

export default node;
