import React, { memo } from "react"
import { Media, Text, FlexColumn } from "@opensea/ui-kit"
import { graphql } from "relay-runtime"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"
import { SelectOption } from "@/design-system/Select"
import {
  HomepageRankingsRow,
  HomePageStatsTab,
} from "@/features/home/components/HomePageStats/types"
import { SORT_BY_TO_TIME_WINDOW } from "@/features/rankings"
import {
  formatCollectionVolume,
  formatCollectionFloorPrice,
  showPreciseVolume,
} from "@/features/rankings/components/RankingsPage/utils"
import { useIsFloorPricePercentChangeEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { StatsTimeWindow } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { StatsTableHomepageRowData$data } from "@/lib/graphql/__generated__/StatsTableHomepageRowData.graphql"
import { StatsTableHomepageRowLiveData$data } from "@/lib/graphql/__generated__/StatsTableHomepageRowLiveData.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { bn } from "@/lib/helpers/numberUtils"
import { STATS_EMPTY_PRICE_DISPLAY } from "../../../../constants"
import { StatsHomepageCollectionCell } from "./StatsHomepageCollectionCell.react"

const readHomepageRowLiveData =
  inlineFragmentize<StatsTableHomepageRowLiveData$data>(
    graphql`
      fragment StatsTableHomepageRowLiveData on CollectionType @inline {
        statsV2 {
          floorPrice {
            unit
            eth
            symbol
          }
          oneHourChange
          oneHourVolume {
            eth
            unit
            symbol
          }
          sixHourChange
          sixHourVolume {
            eth
            unit
            symbol
          }
          oneDayChange
          oneDayVolume {
            eth
            unit
            symbol
          }
          sevenDayChange
          sevenDayVolume {
            eth
            unit
            symbol
          }
        }
      }
    `,
    identifiers => identifiers,
  )

const readHomepageRowData = inlineFragmentize<StatsTableHomepageRowData$data>(
  graphql`
    fragment StatsTableHomepageRowData on CollectionType @inline {
      statsV2 {
        thirtyDayChange
        thirtyDayVolume {
          eth
          unit
          symbol
        }
        totalVolume {
          eth
          unit
          symbol
        }
      }
    }
  `,
  identifiers => identifiers,
)

type RankingsTableFullRowProps = {
  activeTab: HomePageStatsTab
  collectionCellWidth: string
  data: HomepageRankingsRow
  index: number
  indexStart: 1 | 6
  isSmallScreen?: boolean
  sortBy: SelectOption
  collectionCellMaxWidth: number
  floorPricePercentChangeSupported: boolean
}

export const RANK_COLUMN_WIDTH_LARGE = "48px"
export const RANK_COLUMN_WIDTH_SMALL = "32px"

export const getHomepageRowData = ({
  data,
  timeWindow,
}: {
  data: HomepageRankingsRow
  timeWindow?: StatsTimeWindow
}) => {
  const { statsV2: liveStats } = readHomepageRowLiveData(data)
  const { statsV2: stats } = readHomepageRowData(data)
  const floorPrice = liveStats.floorPrice

  switch (timeWindow) {
    case "ONE_HOUR": {
      return {
        floorPrice,
        volumeChange: bn(liveStats.oneHourChange),
        volume: liveStats.oneHourVolume,
      }
    }
    case "SIX_HOUR": {
      return {
        floorPrice,
        volumeChange: bn(liveStats.sixHourChange),
        volume: liveStats.sixHourVolume,
      }
    }
    case "ONE_DAY": {
      return {
        floorPrice,
        volumeChange: bn(liveStats.oneDayChange),
        volume: liveStats.oneDayVolume,
      }
    }
    case "SEVEN_DAY": {
      return {
        floorPrice,
        volumeChange: bn(liveStats.sevenDayChange),
        volume: liveStats.sevenDayVolume,
      }
    }
    case "THIRTY_DAY": {
      return {
        floorPrice,
        volumeChange: bn(stats.thirtyDayChange),
        volume: stats.thirtyDayVolume,
      }
    }
    default: {
      return {
        floorPrice,
        volumeChange: bn(0),
        volume: stats.totalVolume,
      }
    }
  }
}

const _StatsTableHomepageRow = ({
  activeTab,
  collectionCellWidth,
  data,
  indexStart,
  isSmallScreen = false,
  sortBy,
  collectionCellMaxWidth,
  floorPricePercentChangeSupported,
}: RankingsTableFullRowProps) => {
  const t = useTranslate("statsV2")
  const timeWindow = SORT_BY_TO_TIME_WINDOW.get(sortBy.value)

  // feature flag (should be removed eventually)
  const floorPricePercentChangeEnabled = useIsFloorPricePercentChangeEnabled()

  const { floorPrice, volume, volumeChange } = getHomepageRowData({
    data,
    timeWindow,
  })

  const volumeString = formatCollectionVolume(
    bn(volume.unit),
    showPreciseVolume(sortBy.value),
  )
  const volumeSymbol = volume.symbol

  const volumeChangeString = formatCollectionVolume(volumeChange.times(100))
  const isVolumeChangePositive = volumeChange.isGreaterThan(0)

  const floorPriceChange = bn(data.floorPricePercentChange || 0)
  const floorPriceChangeString = formatCollectionVolume(
    floorPriceChange.times(100),
  )
  const isFloorPriceChangePositive = floorPriceChange.isGreaterThan(0)

  const floorPriceString = floorPrice?.unit
    ? formatCollectionFloorPrice(floorPrice.unit)
    : undefined
  const floorPriceSymbol = floorPrice?.symbol
  const hasFloorPrice = !(
    floorPriceString === undefined || floorPriceString === "0"
  )

  const rankCell = (
    <>
      <Media lessThan="xl">
        <Flex
          alignItems="center"
          height="100%"
          minWidth="22px"
          width={RANK_COLUMN_WIDTH_SMALL}
        >
          <Text.Body weight="semibold">{data.index + indexStart}</Text.Body>
        </Flex>
      </Media>
      <Media greaterThanOrEqual="xl">
        <Flex alignItems="center" height="100%" width={RANK_COLUMN_WIDTH_LARGE}>
          <Text.Body weight="semibold">{data.index + indexStart}</Text.Body>
        </Flex>
      </Media>
    </>
  )

  const collectionCell = (
    <Flex alignItems="center">
      <StatsHomepageCollectionCell
        collection={data}
        collectionCellMaxWidth={collectionCellMaxWidth}
        subtitle={
          hasFloorPrice && isSmallScreen ? (
            <Flex
              alignItems="center"
              flexShrink={0}
              marginTop="2px"
              width="max-content"
            >
              <Media greaterThanOrEqual="md">
                <Text.Body className="text-secondary" size="small">
                  {t("stats.row.floor", "Floor:")}
                </Text.Body>
                <Text.Body
                  className="ml-0.5 text-secondary"
                  size="small"
                  weight="semibold"
                >
                  {floorPriceString} {floorPriceSymbol}
                </Text.Body>
              </Media>

              <Media lessThan="md">
                <Block className="whitespace-nowrap">
                  <Text.Body className="text-secondary" size="tiny">
                    {t("stats.row.floor", "Floor:")}
                  </Text.Body>
                  <Text.Body
                    className="ml-0.5 text-secondary"
                    size="tiny"
                    weight="semibold"
                  >
                    {floorPriceString} {floorPriceSymbol}
                  </Text.Body>
                </Block>
              </Media>
            </Flex>
          ) : undefined
        }
      />
    </Flex>
  )

  const collectionTableCell = isSmallScreen ? (
    <Flex alignItems="center" flexGrow={1} minWidth="70%">
      <FeatureTable.Cell
        justifyContent="flex-start"
        width={collectionCellWidth}
      >
        {collectionCell}
      </FeatureTable.Cell>
    </Flex>
  ) : (
    <FeatureTable.Cell justifyContent="flex-start" width={collectionCellWidth}>
      {collectionCell}
    </FeatureTable.Cell>
  )

  const volumeTableCell = (
    <FeatureTable.Cell flexShrink={0} justifyContent="flex-end">
      <Media greaterThanOrEqual="md">
        <Text.Body weight="semibold">
          <Overflow>{`${volumeString} ${volumeSymbol}`}</Overflow>
        </Text.Body>
      </Media>

      <Media lessThan="md">
        <Text.Body size="small" weight="semibold">
          <Overflow>{`${volumeString} ${volumeSymbol}`}</Overflow>
        </Text.Body>
      </Media>
    </FeatureTable.Cell>
  )

  const volumeChangeTableCell = volumeChange.isEqualTo(0) ? (
    <Text.Body className="text-secondary" size="tiny" weight="semibold">
      {STATS_EMPTY_PRICE_DISPLAY}
    </Text.Body>
  ) : (
    <FeatureTable.Cell justifyContent="flex-end">
      <Media greaterThanOrEqual="md">
        <Text.Body
          color={isVolumeChangePositive ? "success" : "red-3"}
          size="small"
          weight="semibold"
        >
          {`${isVolumeChangePositive ? "+" : ""}${volumeChangeString}%`}
        </Text.Body>
      </Media>
      <Media lessThan="md">
        <Text.Body
          color={isVolumeChangePositive ? "success" : "red-3"}
          size="tiny"
          weight="semibold"
        >
          {`${isVolumeChangePositive ? "+" : ""}${volumeChangeString}%`}
        </Text.Body>
      </Media>
    </FeatureTable.Cell>
  )

  const floorPriceTableCell = (
    <FeatureTable.Cell flexShrink={0} justifyContent="flex-end">
      <Text.Body responsive size="medium" weight="semibold">
        <Overflow>
          {hasFloorPrice
            ? `${floorPriceString} ${floorPriceSymbol}`
            : STATS_EMPTY_PRICE_DISPLAY}
        </Overflow>
      </Text.Body>
    </FeatureTable.Cell>
  )

  const floorPriceChangeTableCell = floorPriceChange.isEqualTo(0) ? (
    <Text.Body className="text-secondary" size="tiny" weight="semibold">
      {STATS_EMPTY_PRICE_DISPLAY}
    </Text.Body>
  ) : (
    <FeatureTable.Cell justifyContent="flex-end">
      <Text.Body
        color={isFloorPriceChangePositive ? "success" : "red-3"}
        responsive
        size="small"
        weight="semibold"
      >
        {`${isFloorPriceChangePositive ? "+" : ""}${floorPriceChangeString}%`}
      </Text.Body>
    </FeatureTable.Cell>
  )

  const volumeStackedTableCell = (
    <FlexColumn className="w-1/5 items-end justify-center">
      <Flex className="text-center">{volumeTableCell}</Flex>
      {activeTab === "top" ? (
        <Flex className="text-center" marginTop="2px">
          {volumeChangeTableCell}
        </Flex>
      ) : null}
    </FlexColumn>
  )

  const floorPriceStackedTableCell = (
    <FlexColumn className="w-1/5 items-end justify-center">
      <Flex className="text-center">{floorPriceTableCell}</Flex>
      {activeTab === "top" &&
      hasFloorPrice &&
      floorPricePercentChangeSupported &&
      floorPricePercentChangeEnabled ? (
        <Flex className="text-center" marginTop="2px">
          {floorPriceChangeTableCell}
        </Flex>
      ) : null}
    </FlexColumn>
  )

  return (
    <>
      {rankCell}
      {collectionTableCell}
      {!isSmallScreen && floorPriceStackedTableCell}
      {volumeStackedTableCell}
    </>
  )
}

export const StatsTableHomepageRow = memo(_StatsTableHomepageRow)
