import React from "react"
import { Media, Text, Container } from "@opensea/ui-kit"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Block } from "@/design-system/Block"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCarouselSkeleton } from "@/features/home/components/HomePageCarouselSkeleton.react"
import { useTranslate } from "@/hooks/useTranslate"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { CategoryCarousel } from "./BrowseCategories/CategoryCarousel.react"

const EVENT_SOURCE = "BrowseCategories"

type Props = {
  currentCategory?: string
}

export const BrowseCategories = ({ currentCategory }: Props) => {
  const t = useTranslate("components")

  const title = t("browseCategories.exploreCategories", "Explore Categories")

  return (
    <AnalyticsContextProvider eventSource={EVENT_SOURCE}>
      {/* Overflow hidden used for Carousel "card" variants that require a visible overflow */}
      <Container className="overflow-x-hidden">
        <Block paddingBottom={0}>
          <Grid.Item marginBottom={{ _: "12px", lg: "24px" }}>
            <Media lessThan="lg">
              <Text.Heading size="small">{title}</Text.Heading>
            </Media>
            <Media greaterThanOrEqual="lg">
              <Text.Heading size="medium">{title}</Text.Heading>
            </Media>
          </Grid.Item>
          <SsrSuspense fallback={<HomePageCarouselSkeleton />}>
            <CategoryCarousel currentCategory={currentCategory} />
          </SsrSuspense>
        </Block>
      </Container>
    </AnalyticsContextProvider>
  )
}
