import { useMemo } from "react"
import { range } from "lodash"
import { useTranslate } from "@/hooks/useTranslate"
import { HomepageRankingsRow, HomePageStatsTab } from "./types"

const HOME_MOCK_ROW_DATA = {} as HomepageRankingsRow
const HOME_PAGE_SIZE = 5
export const HOME_MOCK_ITEMS = range(HOME_PAGE_SIZE).map(
  () => HOME_MOCK_ROW_DATA,
)

export const useTabs = (): { id: HomePageStatsTab; label: string }[] => {
  const t = useTranslate("home")
  return useMemo(() => {
    return [
      {
        id: "trending",
        label: t("statsTable.trendingTab", "Trending"),
      },
      { id: "top", label: t("statsTable.topTab", "Top") },
    ]
  }, [t])
}
