import React from "react"
import { Skeleton } from "@opensea/ui-kit"
import { FeatureTable } from "@/design-system/FeatureTable"
import { Flex } from "@/design-system/Flex"

type Props = {
  cellHeight: string
  renderFloorPrice: boolean
}

export const StatsTableHomepageSkeleton = React.memo(
  function StatsTableHomepageSkeleton({ cellHeight, renderFloorPrice }: Props) {
    return (
      <Flex className="w-full">
        <FeatureTable.Cell height={cellHeight} width="50%">
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row className="items-center">
              <Flex marginRight="16px">
                <Skeleton.Block className="h-16 w-16 rounded-2xl" />
              </Flex>
              <Skeleton.Line className="h-9" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
        <FeatureTable.Cell width="10%" />
        <FeatureTable.Cell marginLeft="2%" width="18%">
          {renderFloorPrice && (
            <Skeleton className="h-auto justify-center">
              <Skeleton.Row>
                <Skeleton.Line className="h-9" />
              </Skeleton.Row>
            </Skeleton>
          )}
        </FeatureTable.Cell>
        <FeatureTable.Cell marginLeft="2%" width="18%">
          <Skeleton className="h-auto justify-center">
            <Skeleton.Row>
              <Skeleton.Line className="h-9" />
            </Skeleton.Row>
          </Skeleton>
        </FeatureTable.Cell>
      </Flex>
    )
  },
)
