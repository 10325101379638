import { useTranslate } from "@/hooks/useTranslate"
export const ASSET_URL = "/static/images/learn-center/"

export const useCarouselSlides = (category?: string) => {
  const t = useTranslate("home")

  const articles = [
    {
      title: t("learnCenter.whatIsNFT", "What is an NFT?"),
      path: "what-are-nfts",
      fileName: "what-is-nft.png",
    },
    {
      title: t("learnCenter.howToBuyNFT", "How to buy an NFT"),
      path: "how-to-buy-nft",
      fileName: "how-to-buy-nft.png",
    },
    {
      title: t("learnCenter.whatIsMinting", "What is minting?"),
      path: "what-is-minting-nft",
      fileName: "what-is-minting.png",
    },
    {
      title: t(
        "learnCenter.stayProtectedWeb3",
        "How to stay protected in web3",
      ),
      path: "how-to-stay-protected-in-web3",
      fileName: "stay-protected-web3.png",
    },
    {
      title: t("learnCenter.howToCreateNFT", "How to create an NFT on OpenSea"),
      path: "how-to-create-an-nft",
      fileName: "how-to-create-nft.png",
    },
    {
      title: t("learnCenter.howToSellNFT", "How to sell an NFT using OpenSea"),
      path: "how-to-sell-nfts",
      fileName: "how-to-sell-nft.png",
    },
    {
      title: t("learnCenter.whatIsCryptoWallet", "What is a crypto wallet?"),
      path: "what-is-crypto-wallet",
      fileName: "what-is-crypto-wallet.png",
    },
    {
      title: t("learnCenter.whoIsOpenSea", "Who is OpenSea?"),
      path: "who-is-opensea",
      fileName: "who-is-opensea.png",
    },
  ]

  const categoryArticles: Record<string, (typeof articles)[number]> = {
    music: {
      title: t("learnCenter.whatAreMusic", "What are music NFTs?"),
      path: "what-are-music-nfts",
      fileName: "what-are-music-nfts.png",
    },
    art: {
      title: t("learnCenter.whatAreArt", "What are art NFTs?"),
      path: "art-nfts",
      fileName: "art-nfts.png",
    },
    photography: {
      title: t("learnCenter.whatArePhotography", "What are photography NFTs?"),
      path: "what-are-photography-nfts",
      fileName: "what-are-photography-nfts.png",
    },
    gaming: {
      title: t("learnCenter.whatAreGaming", "What are gaming NFTs?"),
      path: "gaming-nfts",
      fileName: "gaming-nfts.png",
    },
    pfps: {
      title: t("learnCenter.whatArePFPs", "What are profile picture NFTs?"),
      path: "what-are-profile-picture-nfts",
      fileName: "what-are-profile-picture-nfts.png",
    },
    memberships: {
      title: t("learnCenter.whatAreMemberships", "What are NFT memberships?"),
      path: "what-are-nft-memberships",
      fileName: "what-are-nft-memberships.png",
    },
  }

  if (category) {
    articles.unshift(categoryArticles[category])
  }

  return articles
}
