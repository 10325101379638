import React, { memo, Suspense, useMemo, useState } from "react"
import {
  Media,
  useIsHydrated,
  useIsLessThanLg,
  Text,
  Container,
} from "@opensea/ui-kit"
import { useWindowSize } from "@react-hook/window-size"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { Flex } from "@/design-system/Flex"
import { Grid } from "@/design-system/Grid/Grid.react"
import { SelectOption } from "@/design-system/Select"
import useSortableHeaders from "@/design-system/Table/useSortableHeaders"
import { HomePageCollectionSubscriptions } from "@/features/live-updates/components/HomePageSubscriptions/HomePageSubscriptions.react"
import {
  getTopCollectionsSortBy,
  getTrendingCollectionsSortBy,
  isFloorPricePercentChangeSupported,
  SORT_BY_TO_TIME_WINDOW,
  useSortByTimeOptions,
  useTrendingSortByTimeOptions,
} from "@/features/rankings/components/RankingsPage/constants"
import { selectOptionSerializer } from "@/features/rankings/components/RankingsPage/utils"
import { StatsTableHomepageSkeleton } from "@/features/stats/components/StatsTableHomepageRow"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useChainOptions } from "@/hooks/useChains/useChainOptions"
import { useTranslate } from "@/hooks/useTranslate"
import { HomePageStatsTables_data$key } from "@/lib/graphql/__generated__/HomePageStatsTables_data.graphql"
import { HomePageStatsTablesLazyQuery } from "@/lib/graphql/__generated__/HomePageStatsTablesLazyQuery.graphql"
import { useTabs } from "./constants"
import { HomePageStats } from "./HomePageStats.react"
import { HomePageStatsTableTabs } from "./HomePageStatsTableTabs.react"
import { HomePageStatsTab } from "./types"
import { getCollectionSlugs } from "./utils"

type Props = {
  isTrendingTab: boolean
  currentTab: HomePageStatsTab
  dataKey: HomePageStatsTables_data$key | null
  sortBy: SelectOption
  floorPricePercentChangeSupported: boolean
}

type HomePageStatsTablesProps = {
  category?: string
  isMaverick?: boolean
}

const PADDING_BETWEEN_SECTIONS_LARGE = 100
const SPACING_BETWEEN_TABLES_LARGE = "5%"
const SPACING_BETWEEN_TABLES_SMALL = "3%"
const NUM_SECTIONS_LARGE = 2
const TABLE_PADDING = 32

const _HomePageStatsTables = ({
  category,
  isMaverick = false,
}: HomePageStatsTablesProps) => {
  const sortByTimeOptions = useSortByTimeOptions()
  const trendingSortByTimeOptions = useTrendingSortByTimeOptions()
  const chainOptions = useChainOptions()
  const [sortBy, setSortBy] = useState<SelectOption<string>>(
    sortByTimeOptions[2],
  )
  const [chain, setChain] = useState<SelectOption>(chainOptions[0])
  const floorPricePercentChangeSupported = isFloorPricePercentChangeSupported(
    sortBy.value,
    category,
  )
  const dataKey = useLazyLoadQuery<HomePageStatsTablesLazyQuery>(
    graphql`
      query HomePageStatsTablesLazyQuery(
        $chain: [ChainScalar!]
        $categories: [CategorySlug!]
        $categoriesV2: [CategoryV2Slug!]!
        $isCategory: Boolean!
        $trendingCollectionsSortBy: TrendingCollectionSort
        # TrendingCollectionSort is an ENUM, don't assume it's only used for trending collections.
        $topCollectionsSortBy: TrendingCollectionSort
        $fromHomePage: Boolean!
        $statsTimeWindow: StatsTimeWindow
        $floorPricePercentChange: Boolean!
      ) {
        ...HomePageStatsTables_data
          @arguments(
            chain: $chain
            categories: $categories
            categoriesV2: $categoriesV2
            isCategory: $isCategory
            trendingCollectionsSortBy: $trendingCollectionsSortBy
            topCollectionsSortBy: $topCollectionsSortBy
            fromHomePage: $fromHomePage
            statsTimeWindow: $statsTimeWindow
            floorPricePercentChange: $floorPricePercentChange
          )
      }
    `,
    {
      chain:
        chain.value === "all"
          ? null
          : ([selectOptionSerializer(chain)] as ReadonlyArray<ChainIdentifier>),
      categories: category ? [category] : null,
      categoriesV2: category ? [category] : [],
      isCategory: isMaverick,
      trendingCollectionsSortBy: getTrendingCollectionsSortBy(
        sortBy.value,
        category,
      ),
      topCollectionsSortBy: getTopCollectionsSortBy(sortBy.value),
      fromHomePage: false,
      statsTimeWindow: SORT_BY_TO_TIME_WINDOW.get(sortBy.value),
      floorPricePercentChange: floorPricePercentChangeSupported,
    },
  )
  const COLLECTION_STATS_TABS = useTabs()

  const [currentTab, setCurrentTab] = useState<HomePageStatsTab>(
    COLLECTION_STATS_TABS[0].id,
  )

  const tabs = useMemo(
    () => (
      <HomePageStatsTableTabs
        category={category}
        chain={chain}
        chainOptions={chainOptions}
        currentTab={currentTab}
        setChain={setChain}
        setCurrentTab={setCurrentTab}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortByTimeOptions={sortByTimeOptions}
        trendingSortByTimeOptions={trendingSortByTimeOptions}
      />
    ),
    [
      category,
      chain,
      chainOptions,
      currentTab,
      sortBy,
      sortByTimeOptions,
      trendingSortByTimeOptions,
    ],
  )

  const isHydrated = useIsHydrated()
  if (!isHydrated) {
    return <HomePageStatsTablesSkeleton />
  }

  return (
    <>
      <Container
        className="mb-4 gap-2 lg:gap-0"
        data-testId="Home--statsTableHeader"
      >
        {tabs}
      </Container>
      <Suspense fallback={<HomePageStatsTablesSkeleton />}>
        <HomePageStatsTablesGrid
          currentTab={currentTab}
          dataKey={dataKey}
          floorPricePercentChangeSupported={floorPricePercentChangeSupported}
          isTrendingTab={currentTab === COLLECTION_STATS_TABS[0].id}
          sortBy={sortBy}
        />
      </Suspense>
    </>
  )
}

const HomePageStatsTablesGrid = ({
  currentTab,
  dataKey,
  floorPricePercentChangeSupported,
  sortBy,
  isTrendingTab,
}: Props) => {
  const t = useTranslate("home")
  const [windowWidth] = useWindowSize()
  const collectionData = useFragment(
    /* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
    graphql`
      fragment HomePageStatsTables_data on Query
      @argumentDefinitions(
        chain: { type: "[ChainScalar!]" }
        categories: { type: "[CategorySlug!]" }
        categoriesV2: { type: "[CategoryV2Slug!]!" }
        trendingCollectionsSortBy: { type: "TrendingCollectionSort" }
        topCollectionsSortBy: { type: "TrendingCollectionSort" }
        isCategory: { type: "Boolean!" }
        fromHomePage: { type: "Boolean!" }
        statsTimeWindow: { type: "StatsTimeWindow" }
        floorPricePercentChange: { type: "Boolean!" }
      ) {
        topCollectionsByCategory(
          first: 15
          chains: $chain
          categories: $categoriesV2
          sortBy: $topCollectionsSortBy
        ) {
          edges {
            node {
              createdDate
              name
              slug
              logo
              isVerified
              floorPricePercentChange(statsTimeWindow: $statsTimeWindow)
                @include(if: $floorPricePercentChange)
              ...StatsHomepageCollectionCell_collection
              ...collection_url
              ...StatsTableHomepageRowData
              ...StatsTableHomepageRowLiveData
            }
          }
        }
        trendingCollectionsByCategory(
          first: 15
          topCollectionLimit: 500
          chains: $chain
          categories: $categoriesV2
          sortBy: $trendingCollectionsSortBy
        ) @include(if: $isCategory) {
          edges {
            node {
              createdDate
              name
              slug
              logo
              isVerified
              floorPricePercentChange(statsTimeWindow: $statsTimeWindow)
                @include(if: $floorPricePercentChange)
              ...StatsHomepageCollectionCell_collection
              ...collection_url
              ...StatsTableHomepageRowData
              ...StatsTableHomepageRowLiveData
            }
          }
        }
        trendingCollections(
          first: 15
          topCollectionLimit: 500
          chains: $chain
          categories: $categories
          sortBy: $trendingCollectionsSortBy
          fromHomePage: $fromHomePage
        ) @skip(if: $isCategory) {
          edges {
            node {
              createdDate
              name
              slug
              logo
              isVerified
              floorPricePercentChange(statsTimeWindow: $statsTimeWindow)
                @include(if: $floorPricePercentChange)
              ...StatsHomepageCollectionCell_collection
              ...collection_url
              ...StatsTableHomepageRowData
              ...StatsTableHomepageRowLiveData
            }
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields, relay/must-colocate-fragment-spreads */
    dataKey,
  )
  const headers = useMemo(() => {
    return [
      t("statsTable.collectionRank", "Rank"),
      t("statsTable.collectionHeader", "Collection"),
      t("statsTable.floorPriceHeader", "Floor Price"),
      t("statsTable.volumeHeader", "Volume"),
    ]
  }, [t])

  const isLarge = !useIsLessThanLg()
  // Calculate max width for each row
  // 1. If large we take window width and subtract table padding and padding between sections
  // and divide by num sections
  // 2. If small, just subtract windowWidth by table padding
  const rowMaxWidth = useMemo(() => {
    if (isLarge) {
      return (
        (windowWidth - TABLE_PADDING - PADDING_BETWEEN_SECTIONS_LARGE) /
        NUM_SECTIONS_LARGE
      )
    } else {
      return windowWidth - TABLE_PADDING
    }
  }, [isLarge, windowWidth])

  const headerComponents = useSortableHeaders(
    useMemo(
      () => [
        ...headers.map(columnSort => ({
          header: <StatsColumnHeader>{columnSort}</StatsColumnHeader>,
        })),
      ],
      [headers],
    ),
    { index: 1, direction: "desc" },
  )

  const columnData = isTrendingTab
    ? collectionData?.trendingCollections
      ? collectionData.trendingCollections
      : collectionData?.trendingCollectionsByCategory ?? null
    : collectionData?.topCollectionsByCategory ?? null

  const collectionSlugs = getCollectionSlugs(columnData)

  return (
    <>
      <HomePageCollectionSubscriptions slugs={collectionSlugs} />
      <Media greaterThanOrEqual="lg">
        <Container className="mb-6">
          <Grid data-testid="Home--statsTable">
            <Grid.Item lg={6} marginRight={SPACING_BETWEEN_TABLES_LARGE}>
              <HomePageStats
                activeTab={currentTab}
                data={columnData}
                floorPricePercentChangeSupported={
                  floorPricePercentChangeSupported
                }
                indexStart={1}
                isLoading={!columnData}
                rowMaxWidth={rowMaxWidth}
                sortBy={sortBy}
                sortableHeaders={headerComponents}
              />
            </Grid.Item>
            <Grid.Item lg={6} marginLeft={SPACING_BETWEEN_TABLES_LARGE}>
              <HomePageStats
                activeTab={currentTab}
                data={columnData}
                floorPricePercentChangeSupported={
                  floorPricePercentChangeSupported
                }
                indexStart={6}
                isLoading={!columnData}
                rowMaxWidth={rowMaxWidth}
                sortBy={sortBy}
                sortableHeaders={headerComponents}
              />
            </Grid.Item>
          </Grid>
        </Container>
      </Media>
      <Media lessThan="lg">
        <MobileStatsTableWrapper>
          <MobileStatsTableContainer>
            <Grid className="gap-y-2" data-testid="Home--statsTable">
              <Grid.Item marginRight={SPACING_BETWEEN_TABLES_SMALL} xs={6}>
                <HomePageStats
                  activeTab={currentTab}
                  data={columnData}
                  floorPricePercentChangeSupported={
                    floorPricePercentChangeSupported
                  }
                  indexStart={1}
                  isLoading={!columnData}
                  rowMaxWidth={rowMaxWidth}
                  sortBy={sortBy}
                  sortableHeaders={headerComponents}
                />
              </Grid.Item>
              <Grid.Item marginLeft={SPACING_BETWEEN_TABLES_SMALL} xs={6}>
                <HomePageStats
                  activeTab={currentTab}
                  data={columnData}
                  floorPricePercentChangeSupported={
                    floorPricePercentChangeSupported
                  }
                  indexStart={6}
                  isLoading={!columnData}
                  rowMaxWidth={rowMaxWidth}
                  sortBy={sortBy}
                  sortableHeaders={headerComponents}
                />
              </Grid.Item>
            </Grid>
          </MobileStatsTableContainer>
        </MobileStatsTableWrapper>
      </Media>
    </>
  )
}

const HomePageStatsTablesSkeleton = () => {
  return (
    <Container>
      <Media greaterThanOrEqual="lg">
        <Grid>
          <Grid.Item xs={6}>
            {new Array(5).fill(null).map((_, index) => (
              <Flex key={index} marginRight="50px">
                <StatsTableHomepageSkeleton
                  cellHeight="96px"
                  renderFloorPrice
                />
              </Flex>
            ))}
          </Grid.Item>
          <Grid.Item xs={6}>
            {new Array(5).fill(null).map((_, index) => (
              <Flex key={index + 5} marginLeft="50px">
                <StatsTableHomepageSkeleton
                  cellHeight="96px"
                  renderFloorPrice
                />
              </Flex>
            ))}
          </Grid.Item>
        </Grid>
      </Media>

      <Media lessThan="lg">
        <Grid>
          <Grid.Item>
            {new Array(5).fill(null).map((_, index) => (
              <Flex key={index}>
                <StatsTableHomepageSkeleton
                  cellHeight="79px"
                  renderFloorPrice={false}
                />
              </Flex>
            ))}
          </Grid.Item>
        </Grid>
      </Media>
    </Container>
  )
}

export const HomePageStatsTables = memo(_HomePageStatsTables)

const StatsColumnHeader = styled(Text).attrs({
  size: "small",
  className: "text-secondary",
})``

const MobileStatsTableWrapper = styled(Container)`
  width: 100%;
  overflow: auto;
  padding-right: 0;
  padding-top: 8px;
  margin-bottom: 24px;
`

const MobileStatsTableContainer = styled.div`
  width: 170%;
  padding-right: 16px;
`
