import React from "react"
import {
  useIsLessThanLg,
  useIsLessThanMd,
  useIsLessThanSm,
  useIsLessThanXXXl,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { HomePageHeaderCollectionItem } from "@/features/home/components/HeaderCollectionItem.react"
import { HomePageCarousel } from "@/features/home/components/HomePageCarousel.react"
import { HomePageCarouselSkeleton } from "@/features/home/components/HomePageCarouselSkeleton.react"
import { useIsMounted } from "@/hooks/useIsMounted"
import { HomePageHeaderCarousel_data$key } from "@/lib/graphql/__generated__/HomePageHeaderCarousel_data.graphql"
import { graphql } from "@/lib/graphql/graphql"

type Props = {
  dataKey: HomePageHeaderCarousel_data$key | null
  handleSlideIndexChange?: (str: string) => void
}

export const responsiveConfig = {
  default: {
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  sm: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
  md: {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 16,
  },
  lg: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xl: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xxl: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xxxl: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
}

export const HomePageHeaderCarousel = ({
  dataKey,
  handleSlideIndexChange,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment HomePageHeaderCarousel_data on HomePageHeaderCarouselType
      @relay(plural: true) {
        collection {
          name
          logo
        }
        overrideUrl
        ...HeaderCollectionItemHomepage_data
      }
    `,
    dataKey,
  )

  const isMounted = useIsMounted()
  const isXXXL = !useIsLessThanXXXl()
  const isLarge = !useIsLessThanLg()
  const isMedium = !useIsLessThanMd()
  const isSmall = !useIsLessThanSm()

  if (!data || !data.length) {
    return null
  }

  if (!isMounted) {
    return <HomePageCarouselSkeleton />
  }

  let priorityImagesCount = 1
  if (isXXXL) {
    priorityImagesCount = 6
  } else if (isLarge) {
    priorityImagesCount = 4
  } else if (isMedium) {
    priorityImagesCount = 3
  } else if (isSmall) {
    priorityImagesCount = 2
  }

  const onlyLogoOrOverrideUrl = data.filter(
    entry => entry.overrideUrl || entry.collection.logo,
  )

  const slides = onlyLogoOrOverrideUrl.map((entry, index) => {
    return {
      id: `${entry.collection.name}${index}`,
      collection: (
        <HomePageHeaderCollectionItem
          dataKey={entry}
          index={index}
          isPriorityImage={index < priorityImagesCount}
          size={800}
        />
      ),
    }
  })

  const maxSlides = slides.slice(0, 12)

  let i = 0
  while (maxSlides.length < 12) {
    maxSlides.push({ id: `blank-${i}`, collection: <></> })
    i++
  }

  return (
    <HomePageCarousel
      autoplayConfig={{
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      handleSlideIndexChange={handleSlideIndexChange}
      id="header-carousel"
      responsiveConfig={responsiveConfig}
      slides={maxSlides}
      testId="Home--header-carousel"
    />
  )
}
