/**
 * @generated SignedSource<<148ad71b37002b78d1d7460831186205>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderCollectionItemCategory_data$data = {
  readonly collection: {
    readonly logo: string | null;
    readonly name: string;
    readonly slug: string;
    readonly statsV2: {
      readonly floorPrice: {
        readonly symbol: string;
        readonly unit: string;
      } | null;
    };
    readonly verificationStatus: VerificationStatus;
    readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  };
  readonly overrideUrl: string | null;
  readonly shortDescription: string | null;
  readonly " $fragmentType": "HeaderCollectionItemCategory_data";
};
export type HeaderCollectionItemCategory_data$key = {
  readonly " $data"?: HeaderCollectionItemCategory_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderCollectionItemCategory_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderCollectionItemCategory_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "collection_url",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCategory",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verificationStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionStatsV2Type",
          "kind": "LinkedField",
          "name": "statsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "floorPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overrideUrl",
      "storageKey": null
    }
  ],
  "type": "CategoryPageCarouselType",
  "abstractKey": null
};
})();

(node as any).hash = "b51b2aa6dcf07565894332779a7507d6";

export default node;
