import React, { MouseEvent } from "react"
import {
  Media,
  SpaceBetween,
  classNames,
  useIsLessThanMd,
} from "@opensea/ui-kit"
import _ from "lodash"
import { rgba } from "polished"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { Carousel } from "@/design-system/Carousel"
import { Flex } from "@/design-system/Flex"
import { Menu } from "@/design-system/Menu"
import { Skeleton } from "@opensea/ui-kit"
import { useRouter } from "@/hooks/useRouter"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import { media } from "@/styles/styleUtils"
import { CategoryTabs } from "./types"

type TabProp = Omit<TabItemProps, "active" | "baseUrl">

type CategoryNavigationProps = {
  color?: string
  currentTab: CategoryTabs
  tabs: TabProp[]
  handleClick?: (id?: string) => void
  isLoading?: boolean
  baseUrl?: string
  labelVariant?: "small" | "large"
}

export const CategoryNavigation = ({
  color,
  tabs,
  currentTab,
  handleClick,
  baseUrl = "/",
  isLoading = false,
  labelVariant,
}: CategoryNavigationProps) => {
  const isLessThanMd = useIsLessThanMd()

  if (isLoading) {
    return (
      <Flex className="w-full">
        <Skeleton.Row>
          <Flex>
            <Media greaterThanOrEqual="sm">
              <Skeleton.Line className="h-11 w-[700px]" />
            </Media>
            <Media lessThan="sm">
              <Skeleton.Line className="h-8 w-[300px]" />
            </Media>
          </Flex>
        </Skeleton.Row>
      </Flex>
    )
  }
  const slides = tabs.map((tab, idx) => {
    return {
      id: tab.id || `${idx}`,
      item: (
        <TabItem
          active={tab.id === currentTab}
          baseUrl={baseUrl}
          color={color}
          handleClick={tab.handleClick ?? handleClick}
          id={tab.id}
          key={tab.id || idx}
          label={tab.label}
          labelVariant={labelVariant}
          path={tab.path}
        />
      ),
    }
  })
  return (
    <Container data-testid="Homepage--category-nav">
      <Block as="nav" className="w-full" overflow="hidden">
        <Media lessThan="md">
          {mediaClassNames => (
            <MobileExperimentalCarousel
              className={mediaClassNames}
              enableArrowControls={isLessThanMd}
              enableFreeScroll
              enableMousewheel
              id="category-navigation"
              key={String(isLessThanMd)}
              overrides={carouselOverrides()}
              renderSlide={slide => slide.item}
              responsiveConfig={responsiveConfig}
              showScrollbar={false}
              slides={slides}
              slidesPerView="auto"
              watchSlidesProgress
            />
          )}
        </Media>
        <Media greaterThanOrEqual="md">
          {mediaClassNames => (
            <SpaceBetween className={classNames("items-end", mediaClassNames)}>
              <StyledNavbar>
                <FlexWithGap>
                  {tabs.map((tab, idx) => {
                    return (
                      <TabItem
                        active={tab.id === currentTab}
                        baseUrl={baseUrl}
                        color={color}
                        handleClick={tab.handleClick ?? handleClick}
                        id={tab.id}
                        key={tab.id || idx}
                        label={tab.label}
                        labelVariant={labelVariant}
                        path={tab.path}
                      />
                    )
                  })}
                </FlexWithGap>
              </StyledNavbar>
            </SpaceBetween>
          )}
        </Media>
      </Block>
    </Container>
  )
}

const MobileExperimentalCarousel = styled(Carousel)`
  .swiper-slide {
    width: auto;
  }

  &:has(+ .swiper-nav-right):has(+ :not(.swiper-button-disabled)) {
    mask: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
    -webkit-mask: linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%
    );
  }

  .swiper-nav-left:not(.swiper-button-disabled) + & {
    mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
    -webkit-mask: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%
    );

    :has(+ .swiper-nav-right):has(+ :not(.swiper-button-disabled)) {
      /* TODO - get this working for Firefox */
      mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%),
        linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
      mask-composite: exclude;
      -webkit-mask: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 10%
        ),
        linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);
      -webkit-mask-composite: source-in;
    }
  }
`

const Container = styled(Flex)`
  z-index: 2;
  width: 100%;
`
const StyledNavbar = styled(Menu).attrs({ direction: "horizontal" })`
  justify-content: left;
  width: 100%;
  margin: 0px;
  align-items: center;

  :hover {
    overflow: auto;
  }
`
const FlexWithGap = styled(Flex)`
  margin: 0px;
  gap: 4px;
  ${media({
    md: css`
      gap: 8px;
    `,
  })}
  margin-left: -8px;
`
const StyledMenuItem = styled(Menu.Item)<{ $color?: string }>`
  display: flex;
  padding: 8px 16px;
  width: fit-content;
  cursor: pointer;
  color: ${props => props.$color};
  background: ${props =>
    props.$active ? rgba(props.theme.colors.white, 0.12) : `transparent`};
  &:hover {
    background: ${props => rgba(props.theme.colors.white, 0.12)};
    color: ${props => props.$color};
  }
  &&& {
    &:after {
      background-color: transparent;
      transition: none;
    }
  }
`

type TabItemProps = {
  color?: string
  label: string
  labelVariant?: "small" | "large"
  id?: string
  path?: string
  handleClick?: (id: string | undefined, event: MouseEvent<HTMLElement>) => void
} & Active &
  Pick<CategoryNavigationProps, "baseUrl">

const TabItem = ({
  color,
  label,
  id,
  active,
  handleClick,
  baseUrl,
  path,
  labelVariant = "small",
}: TabItemProps) => {
  const router = useRouter()
  const largeTabLabel = <LargeLabel>{label}</LargeLabel>

  const smallTabLabel = (
    <Label className="text-[14px] md:text-[16px]">{label}</Label>
  )

  return (
    <StyledMenuItem
      $active={active}
      $color={color}
      aria-selected={!!active}
      direction="horizontal"
      eventParams={{
        category: label,
      }}
      eventSource="CategoryNavigationItemClick"
      href={
        baseUrl
          ? `${baseUrl}${
              path ?? stringifyQueryParams({ ...router.queryParams, tab: id })
            }`
          : undefined
      }
      role="tab"
      scroll={false}
      onClick={e => {
        if (handleClick) {
          handleClick(id, e)
        }
      }}
    >
      {labelVariant === "large" ? largeTabLabel : smallTabLabel}
    </StyledMenuItem>
  )
}

type Active = {
  active: boolean
}

const Label = styled(Menu.Title)`
  margin-right: 0px;
  white-space: nowrap;
`

const LargeLabel = styled(Menu.Title).attrs({ variant: "small-h2" })`
  margin-right: 0px;
  white-space: nowrap;
`
const carouselOverrides = () => {
  return {
    arrowSize: 24,
    arrowStyles: css`
      && {
        width: 24px;
        height: 24px;
        background-color: transparent;
        i {
          color: white;
          @media (hover: hover) {
            &:hover {
              box-shadow: none;
              background: none;
            }
          }
        }
        box-shadow: none;
        @media (hover: hover) {
          &:hover {
            box-shadow: none;
            background: none;
          }
        }

        ${media(
          {
            sm: css`
              display: none;
            `,
          },
          { variant: "lessThan" },
        )}
      }
    `,
    containerStyles: css`
      .swiper-button-disabled {
        display: none;
      }
    `,
  }
}

const responsiveConfig = {
  default: {
    slidesPerView: "auto" as const,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  sm: {
    slidesPerView: "auto" as const,
    slidesPerGroup: 1,
    spaceBetween: 12,
  },
}
