import React, { memo } from "react"
import { useHomePageCollectionStatsEvent } from "@/features/live-updates/hooks/useHomePageCollectionStatsEvent/useHomePageCollectionStatsEvent"

const _HomePageCollectionSubscriptions = ({
  slugs,
}: {
  slugs: Array<string>
}) => {
  return (
    <>
      {slugs.map(slug => {
        return <HomePageCollectionSubscription key={slug} slug={slug} />
      })}
    </>
  )
}

export const HomePageCollectionSubscriptions = memo(
  _HomePageCollectionSubscriptions,
)

const _HomePageCollectionSubscription = ({ slug }: { slug: string }) => {
  useHomePageCollectionStatsEvent({ slug })
  return <></>
}

const HomePageCollectionSubscription = memo(_HomePageCollectionSubscription)
