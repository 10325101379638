import React from "react"
import { Media } from "@opensea/ui-kit"
import clsx from "clsx"
import { graphql, useFragment } from "react-relay"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { Grid } from "@/design-system/Grid/Grid.react"
import { HomePageCollectionCard } from "@/features/home/components/HomePageShelf/HomePageCollectionCard.react"
import { useCarousel } from "@/hooks/useCarousel"
import { CategoryScroller_collections$key } from "@/lib/graphql/__generated__/CategoryScroller_collections.graphql"
import { CategoryScrollerSkeleton } from "./CategoryScrollerSkeleton.react"

type Props = {
  category: string
  collections: CategoryScroller_collections$key | null
}

export const CategoryScroller = ({
  category,
  collections: collectionsDataKey,
}: Props) => {
  const categoryCollections = useFragment(
    graphql`
      fragment CategoryScroller_collections on CollectionType
      @relay(plural: true) {
        relayId
        ...HomePageCollectionCard_data @arguments(timeWindow: ONE_DAY)
      }
    `,
    collectionsDataKey,
  )

  const { showArrows } = useCarousel()

  if (!categoryCollections) {
    return <CategoryScrollerSkeleton />
  }

  const showingTrendingCollections = !showArrows
    ? categoryCollections.slice(0, 6)
    : categoryCollections

  const slides = showingTrendingCollections.map((collection, idx) => {
    return {
      id: `${collection.relayId}`,
      collection: (
        <HomePageCollectionCard
          collection={collection}
          eventParams={{
            idx,
            category,
          }}
          eventSource="CategoryShelfLinkClick"
          volumeWindow="ONE_DAY"
        />
      ),
    }
  })

  return (
    <Grid.Item>
      <Block
        alignSelf="center"
        data-testid="Home--category-scroller"
        marginX={{ _: "-16px", sm: "0px" }}
      >
        <Media lessThan="sm">
          {mediaClassNames => (
            <Carousel
              centeredSlides
              centeredSlidesBounds
              className={clsx(mediaClassNames, "category_scroller_mobile")}
              enableArrowControls={false}
              enableFreeScroll
              enableMousewheel
              id={`category-scroller-${category}-mobile`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              slidesOffsetAfter={16}
              slidesOffsetBefore={16}
              variant="card"
            />
          )}
        </Media>
        <Media greaterThanOrEqual="sm">
          {mediaClassNames => (
            <Carousel
              className={clsx(mediaClassNames, "category_scroller")}
              enableArrowControls
              enableFreeScroll
              enableMousewheel
              id={`category-scroller-${category}`}
              renderSlide={({ collection }) => collection}
              responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
              showScrollbar={false}
              slides={slides}
              variant="card"
            />
          )}
        </Media>
      </Block>
    </Grid.Item>
  )
}
