import React from "react"
import { Media } from "@opensea/ui-kit"
import { graphql, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
  Carousel,
} from "@/design-system/Carousel"
import { useTheme } from "@/design-system/Context"
import { HomePageCarouselSkeleton } from "@/features/home/components/HomePageCarouselSkeleton.react"
import { HomePageSimpleCard } from "@/features/home/components/LearnCenter"
import { useIsMounted } from "@/hooks/useIsMounted"
import { CategoryCarouselQuery } from "@/lib/graphql/__generated__/CategoryCarouselQuery.graphql"

type Props = {
  currentCategory?: string
}

export const CategoryCarousel = ({ currentCategory }: Props) => {
  const isMounted = useIsMounted()
  const theme = useTheme()
  const data = useLazyLoadQuery<CategoryCarouselQuery>(
    graphql`
      query CategoryCarouselQuery {
        categoriesV2 {
          slug
          imageUrl
          name
        }
      }
    `,
    {},
  )
  if (!isMounted) {
    return <HomePageCarouselSkeleton />
  }

  if (!data.categoriesV2.length) {
    return null
  }

  const CATEGORIES = currentCategory
    ? (
        [
          {
            slug: "",
            name: "All",
            imageUrl:
              theme.theme == "light"
                ? "/static/images/category-all.png"
                : "/static/images/category-all-dark.png",
          },
        ] as Array<{
          slug: string
          name: string
          imageUrl: string | null
        }>
      ).concat(
        data.categoriesV2.filter(category => category.slug !== currentCategory),
      )
    : data.categoriesV2

  const slides = CATEGORIES.map((card, index) => {
    const { imageUrl, slug, name } = card
    const url = slug ? `/category/${slug}` : "/"

    return {
      id: `${card.slug}-${index}`,
      collection: (
        <HomePageSimpleCard
          href={url}
          imageUrl={imageUrl || ""}
          shortTitle
          title={name}
        />
      ),
    }
  })

  return (
    <StyledBlock
      alignSelf="center"
      data-testid="Home--category-carousel"
      marginX={{ _: "-16px", sm: "0px" }}
    >
      <Media lessThan="sm">
        <Carousel
          centeredSlides
          centeredSlidesBounds
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="home-category-carousel-mobile"
          data-testid="Home--home_category_carousel_mobile"
          enableArrowControls={false}
          enableFreeScroll
          enableMousewheel
          id="home_category_carousel_mobile"
          renderSlide={({ collection }) => collection}
          responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS}
          showScrollbar={false}
          slides={slides}
          slidesOffsetAfter={16}
          slidesOffsetBefore={16}
          variant="card"
        />
      </Media>
      <Media greaterThanOrEqual="sm">
        <Carousel
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="home-category-carousel"
          data-testid="Home--home_category_carousel"
          enableArrowControls
          enableFreeScroll
          enableMousewheel
          id="home_category_carousel"
          renderSlide={({ collection }) => collection}
          responsiveConfig={{
            ...CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS,
            xl: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 16,
            },
            xxl: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 16,
            },
            xxxl: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 16,
            },
          }}
          showScrollbar={false}
          slides={slides}
          variant="card"
        />
      </Media>
    </StyledBlock>
  )
}

const StyledBlock = styled(Block)`
  .swiper-button-disabled {
    display: none;
  }
`
