import React, { ComponentProps } from "react"
import { Media, classNames } from "@opensea/ui-kit"
import { GlobalCollectionCard } from "@/components/collections/GlobalCollectionCard/GlobalCollectionCard.react"
import { Block } from "@/design-system/Block"
import { Skeleton } from "@opensea/ui-kit"

export const CategoryScrollerSkeleton = () => {
  return (
    <Block alignSelf="center" className="w-full">
      <Media greaterThanOrEqual="xxl">
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(6)].map((_, i) => (
              <GlobalCollectionCard.Skeleton key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>
      <Media between={["xl", "xxl"]}>
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(5)].map((_, i) => (
              <GlobalCollectionCard.Skeleton key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>
      <Media between={["lg", "xl"]}>
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(4)].map((_, i) => (
              <GlobalCollectionCard.Skeleton key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media between={["md", "lg"]}>
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(3)].map((_, i) => (
              <GlobalCollectionCard.Skeleton key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media between={["sm", "md"]}>
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <SkeletonRow>
            {[...Array(2)].map((_, i) => (
              <GlobalCollectionCard.Skeleton key={i} />
            ))}
          </SkeletonRow>
        </Skeleton>
      </Media>

      <Media lessThan="sm">
        <Block paddingBottom="24px">
          <Skeleton>
            <Skeleton.Line className="h-8 w-1/5" />
          </Skeleton>
        </Block>
        <Skeleton className="items-center">
          <Skeleton.Row className="flex w-full flex-nowrap overflow-x-scroll">
            {[...Array(2)].map((_, i) => (
              <Block key={i} minWidth="62vw">
                <GlobalCollectionCard.Skeleton />
              </Block>
            ))}
          </Skeleton.Row>
        </Skeleton>
      </Media>
    </Block>
  )
}

const SkeletonRow = ({
  className,
  ...props
}: ComponentProps<typeof Skeleton.Row>) => (
  <Skeleton.Row
    className={classNames("w-full gap-2.5", className)}
    {...props}
  />
)
